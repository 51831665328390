/** @format */

import {Box, ListItem, ListItemText, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import TransparentSmallButton from 'components/buttons/TransparentSmallButton';

import {sensorName} from 'helpers/sensor_names';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#202020',
    borderRadius: 8,
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    position: 'relative',
    paddingRight: theme.spacing(4),
  },
  icon: {
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    background: '#2B2B2B',
    marginRight: theme.spacing(4),
  },
  title: {
    color: '#D2D2D2',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.169px',
    paddingRight: theme.spacing(2),
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '16px',
    paddingRight: theme.spacing(2),
  },
  startSensorButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    minWidth: '32px',
    width: '32px',
    height: '32px',
    borderRadius: '8px',
    background: '#2B2B2B',
    borderColor: 'transparent',
  },
  startSensorButtonIcon: {
    fontSize: '16px',
    color: '#AE5658',
  },
  startSensorButtonProgressIcon: {
    width: '24px !important',
    height: '24px !important',
  },
}));

const ExcludedRow = props => {
  const classes = useStyles();

  const {t} = useTranslation();

  const handleClick = () => {
    props.onClick(props.check);
  };

  return (
    <Box position='relative'>
      <ListItem className={classes.row} onClick={handleClick}>
        <ListItemText
          disableTypography
          primary={
            <Box display={'flex'} alignItems={'center'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                className={classes.icon}
              >
                <EmojiObjectsOutlinedIcon
                  fontSize='small'
                  htmlColor='#8B8B8B'
                />
              </Box>

              <Typography className={classes.title}>
                {t(sensorName(props.check.sensor.name))}
              </Typography>
            </Box>
          }
        />

        <TransparentSmallButton
          className={classes.startSensorButton}
          onClick={props.handleStartSensor}
        >
          {props.deleteSensorExclusionLoading ? (
            <CircularProgress
              className={classes.startSensorButtonProgressIcon}
              disableShrink
            />
          ) : (
            <PauseCircleFilledOutlinedIcon
              className={classes.startSensorButtonIcon}
            />
          )}
        </TransparentSmallButton>
      </ListItem>
    </Box>
  );
};

export default ExcludedRow;
