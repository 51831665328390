/** @format */

import {IconButton} from '@mui/material';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: '#ECECEC',
      height: 23,
      width: 23,
      '&:hover': {
        backgroundColor: '#E1E1E1',
      },
    },
  }),
);

type Props = {
  type: 'PLUS' | 'MINUS';
  onClick: () => void;
};

const PlusMinusButton = (props: Props) => {
  const classes = useStyles();

  return (
    (<IconButton className={classes.button} onClick={props.onClick} size="large">
      {props.type === 'PLUS' && (
        <img
          alt='Plus'
          height='9'
          src={new URL('./images/plus.png', import.meta.url).href}
          width='9'
        />
      )}
      {props.type === 'MINUS' && (
        <img
          alt='Minus'
          height='2'
          src={new URL('./images/minus.png', import.meta.url).href}
          width='11'
        />
      )}
    </IconButton>)
  );
};

export default PlusMinusButton;
