/** @format */

import clsx from 'clsx';
import {Box, ListItem, ListItemText, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import constants from 'helpers/constants';
import {sensorName} from 'helpers/sensor_names';
import type {Check} from 'types';

const useStyles = makeStyles(theme =>
  createStyles({
    row: {
      backgroundColor: theme.palette.action.disabledBackground,
      borderRadius: 8,
      cursor: 'pointer',
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
      paddingRight: theme.spacing(4),
      position: 'relative',
    },
    avatar: {
      minWidth: 16,
    },
    leftTextContainer: {
      minWidth: 'unset',
    },
    rightTextContainer: {
      flexGrow: 0,
      minWidth: theme.spacing(19),
    },
    foundIconContainer: {
      flexGrow: 0,
      minWidth: 'unset',
    },
    badge: {
      borderBottomLeftRadius: 8,
      borderTopLeftRadius: 8,
      bottom: 0,
      left: 0,
      position: 'absolute',
      top: 0,
      width: theme.spacing(1),
    },
    yellowBadge: {
      backgroundColor: '#EFAE54',
    },
    redBadge: {
      backgroundColor: theme.palette.error.main,
    },
    yellowNew: {
      color: '#EFAE54',
    },
    redNew: {
      color: theme.palette.error.main,
    },
    grayNew: {
      color: 'gray',
    },
    title: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      lineHeight: '22px',
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 18,
      },
    },
    text: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '17px',
    },
    successText: {
      color: theme.palette.success.main,
    },
    foundText: {
      color: theme.palette.text.primary,
    },
    newText: {
      color: theme.palette.error.main,
    },
    backdrop: {
      backgroundColor: theme.backdrop.main,
      position: 'absolute',
      zIndex: 1,
    },
  }),
);

interface Props {
  check: Check;
  onClick: (check: Check) => void;
}

const Row = (props: Props) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const handleClick = () => {
    props.onClick(props.check);
  };

  const checkClassName = props.check.sensor.isError
    ? classes.redBadge
    : classes.yellowBadge;

  const newClassName =
    props.check.new > 0
      ? props.check.sensor.isError
        ? classes.redNew
        : classes.yellowNew
      : classes.grayNew;

  const newText = (check: Check) => {
    const count = check.new;

    if (check.sensor.isError) {
      return t('plurals.new_errors', {count});
    }

    return t('plurals.new_warnings', {count});
  };

  const foundText = (check: Check) => {
    return `${check.found}`;
  };

  const foundIconSrc = (() => {
    if (props.check.found === 0) {
      return new URL('./images/success_icon.png', import.meta.url).href;
    }

    if (props.check.sensor.isError) {
      return new URL('./images/error_icon.png', import.meta.url).href;
    }

    return new URL('./images/warning_icon.png', import.meta.url).href;
  })();

  return (
    <Box position='relative'>
      <ListItem className={classes.row} onClick={handleClick}>
        <Box className={clsx(classes.badge, checkClassName)}>
          {constants.ZWSP}
        </Box>

        <ListItemText
          className={classes.leftTextContainer}
          disableTypography
          primary={
            <Typography className={classes.title}>
              {t(sensorName(props.check.sensor.name))}
            </Typography>
          }
        />

        <ListItemText
          className={classes.rightTextContainer}
          disableTypography
          primary={
            <Typography
              align='right'
              className={clsx(classes.text, classes.foundText)}
            >
              {foundText(props.check)}
            </Typography>
          }
          secondary={
            <Typography
              align='right'
              className={clsx(classes.text, newClassName)}
            >
              {newText(props.check)}
            </Typography>
          }
        />

        <ListItemText
          className={classes.foundIconContainer}
          disableTypography
          primary={
            <Box ml={4}>
              <img alt='Found Icon' height='16' src={foundIconSrc} width='16' />
            </Box>
          }
        />
      </ListItem>
    </Box>
  );
};

export default Row;
