/** @format */

import {useState} from 'react';

import Alert from '@mui/material/Alert';
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Trans} from 'react-i18next';
import {gql, useQuery} from '@apollo/client';
import {useNavigate, useLocation} from 'react-router-dom';

import BackHeader from 'components/BackHeader';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

import SecondarySelect from 'components/selects/SecondarySelect';

import routerHelpers from 'helpers/routerHelpers';
import type {Exception, Platform} from 'types';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useSidebarContext} from 'contexts/SidebarContext';

import SensorSelect from './SensorSelect';
import Row from './Row';

const GET_SENSORS = gql`
  query GetSensors {
    currentUser {
      sensors {
        id
        name
        platform
      }
    }
  }
`;

const GET_EXCEPTIONS = gql`
  query GetExceptions(
    $companyId: ID!
    $sensorName: String!
    $platform: YalperPlatform!
  ) {
    currentUser {
      company(id: $companyId) {
        exceptions(sensorName: $sensorName, platform: $platform) {
          id
          platform
          items {
            field
            operator
            value
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      height: 200,
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

type Company = {
  id: string;
  name: string;
};

export type Sensor = {
  id: string;
  name: string;
  platform: Platform;
};

const ExceptionList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {currentCompany} = useCompanyContext();
  const {companies}: {companies: Company[]} = useSidebarContext();

  const classes = useStyles();

  const [companyId, setCompanyId] = useState<string>(currentCompany.id);
  const [activeSensor, setActiveSensor] = useState<Sensor | null>(null);

  const {
    loading: getSensorsLoading,
    error: getSensorsError,
    data: getSensorsData,
  } = useQuery(GET_SENSORS);

  const {
    loading: getExceptionsLoading,
    error: getExceptionsError,
    data: getExceptionsData,
    refetch: getExceptionsRefetch,
  } = useQuery(GET_EXCEPTIONS, {
    skip: !activeSensor,
    variables: {
      companyId,
      sensorName: activeSensor && activeSensor.name,
      platform: activeSensor && activeSensor.platform,
    },
  });

  routerHelpers.useCallbackOnReturn(getExceptionsRefetch);

  if (getSensorsLoading) return <LinearProgress style={{flex: 1}} />;
  if (getSensorsError) {
    return <Alert severity='error'>{getSensorsError.message}</Alert>;
  }

  const {sensors}: {sensors: Sensor[]} = getSensorsData.currentUser;

  const renderCompanySelect = () => {
    const handleSelect = (value: string) => {
      setCompanyId(value);
    };

    const options = companies.map(v => ({value: v.id, text: v.name}));

    return (
      <Box mt={10}>
        <SecondarySelect
          options={options}
          value={companyId || ''}
          onSelect={handleSelect}
        />
      </Box>
    );
  };

  const renderSensorSelect = () => {
    const handleSelect = (value: string) => {
      const sensor = sensors.find(v => v.id === value) || null;
      setActiveSensor(sensor);
    };

    return (
      <Box mt={2}>
        <SensorSelect
          sensors={sensors}
          value={activeSensor ? activeSensor.id : ''}
          onSelect={handleSelect}
        />
      </Box>
    );
  };

  const renderSensorExceptions = () => {
    if (!activeSensor) {
      return (
        <Box mt={10}>
          <Typography align='center' variant='body1'>
            <Trans>Выберите сенсор для отображения исключений</Trans>
          </Typography>
        </Box>
      );
    }

    if (getExceptionsLoading) {
      return (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      );
    }

    if (getExceptionsError) {
      return <Alert severity='error'>{getExceptionsError.message}</Alert>;
    }

    const {exceptions}: {exceptions: Exception[]} =
      getExceptionsData.currentUser.company;

    if (exceptions.length === 0) {
      return (
        <Box mt={10}>
          <Typography align='center' variant='body1'>
            <Trans>Для данного сенсора еще нет исключений</Trans>
          </Typography>
        </Box>
      );
    }

    const handleDelete = () => {
      getExceptionsRefetch();
    };

    return (
      <Box mt={10}>
        <Grid container spacing={4}>
          {exceptions.map((v, i) => (
            <Grid key={i} item xs={12}>
              <Row exception={v} onDelete={handleDelete} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const renderAddExceptionButton = () => {
    if (!activeSensor) return null;

    const handleClick = () => {
      const {name, platform} = activeSensor;

      navigate(
        `exception?companyId=${companyId}&sensorName=${name}&platform=${platform}`,
        {
          state: {background: location},
        },
      );
    };

    return (
      <Box mt={10}>
        <PrimaryMediumButton onClick={handleClick}>
          <Trans>Добавить исключение</Trans>
        </PrimaryMediumButton>
      </Box>
    );
  };

  return (
    <>
      <Box mb={10}>
        <BackHeader />
      </Box>
      <Typography variant='h1'>
        <Trans>Исключения</Trans>
      </Typography>
      {renderCompanySelect()}
      {renderSensorSelect()}
      {renderSensorExceptions()}
      {renderAddExceptionButton()}
    </>
  );
};

export default ExceptionList;
