/** @format */

import {Box, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import BaseLayout from 'layouts/BaseLayout';
import JuliaBot from 'components/managers/JuliaBot';

const useStyles = makeStyles(_theme => ({
  title: {
    fontSize: 28,
    fontWeight: '600',
    lineHeight: '34px',
  },
  body: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: '18px',
  },
}));

const Oops = () => {
  const classes = useStyles();

  return (
    <BaseLayout>
      <Typography className={classes.title}>
        <Trans>Что-то пошло не так</Trans>
      </Typography>

      <Box mt={4}>
        <Typography className={classes.body}>
          <Trans>
            Чтобы получить доступ в свой кабинет AdSensor и следить за тем,
            качественно ли вам ведут рекламу, напиши вашему персональному
            менеджеру.
          </Trans>
        </Typography>
      </Box>

      <Box mt={6}>
        <JuliaBot />
      </Box>
    </BaseLayout>
  );
};

export default Oops;
