/** @format */

import {useState} from 'react';

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import {Box, LinearProgress, Typography} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import Autocomplete from 'components/inputs/AutocompleteField';
import BackHeader from 'components/BackHeader';
import BoxPlate from 'components/plates/BoxPlate';
import IssueTabs from 'components/tabs/IssueTabs';
import categoriesData from 'pages/Home/Main/Status/Issues/categories.json';
import checkHelpers from 'helpers/checkHelpers';
import routerHelpers from 'helpers/routerHelpers';
import type {Check} from 'types';
import {sensorName} from 'helpers/sensor_names';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import ExcludedRow from './ExcludedRow';
import NeedRegionsRow from './NeedRegionsRow';
import Row from './Row';
import {sortChecks} from './helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoTitle: {
      color: '#8F8F8F',
      fontSize: 14,
      fontWeight: 400,
    },
    infoValue: {
      color: theme.palette.text.primary,
      fontSize: 20,
      fontWeight: 500,
      marginTop: theme.spacing(2),
    },
  }),
);

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      currentCompany {
        sensorExclusions {
          sensorName
        }
        lastChecks {
          id
          checks
          cost
          found
          new
          fixed
          sensor {
            id
            name
            platform
            isError
          }
        }
        regions {
          id
          name
        }
      }
    }
  }
`;

type Tab = 'ALL' | 'IMPROVEMENTS' | 'RECOMMENDATIONS';

interface Category {
  name: string;
  title: string;
  description: string;
  sensors: string[];
}

interface sensorOption {
  id: string;
  title: string;
}

const Category = () => {
  const navigate = useNavigate();

  const searchParams = routerHelpers.useSearchParams();
  const categoryName = searchParams.get('name');

  const {t} = useTranslation();

  const {mixpanel} = useMixpanelContext();

  const [activeTab, setActiveTab] = useState<Tab>('ALL');
  const classes = useStyles();

  const {loading, error, data} = useQuery(GET_CURRENT_USER);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const category = categoriesData.find(
    v => v.name === categoryName,
  ) as Category;

  const {sensorExclusions, lastChecks, regions} =
    data.currentUser.currentCompany;

  const allChecks = R.pipe(
    checkHelpers.groupChecksByName,
    R.filter<Check>(v => category.sensors.includes(v.sensor.name)),
    R.filter<Check>(v => {
      if (activeTab === 'ALL') return true;
      return activeTab === 'IMPROVEMENTS'
        ? v.sensor.isError
        : !v.sensor.isError;
    }),
  )(lastChecks);

  const {
    validChecks,
    excludedChecks,
    needRegionsChecks,
  }: {
    validChecks: Check[];
    excludedChecks: Check[];
    needRegionsChecks: Check[];
  } = checkHelpers.groupedChecks(allChecks, sensorExclusions, regions);

  const renderInfoPlate = () => {
    const checksCount = R.sum(validChecks.map(v => v.checks));
    const foundCount = R.sum(validChecks.map(v => v.found));
    const newCount = R.sum(validChecks.map(v => v.new));

    return (
      <BoxPlate>
        <Box display='flex'>
          <Box flexGrow={1}>
            <Box display='flex' flexDirection='column'>
              <Typography className={classes.infoTitle}>
                <Trans>Проверено объектов</Trans>
              </Typography>
              <Typography className={classes.infoValue}>
                {checksCount}
              </Typography>
            </Box>
          </Box>

          <Box flexGrow={1}>
            <Box display='flex' flexDirection='column'>
              <Typography className={classes.infoTitle}>
                <Trans>Найдено</Trans>
              </Typography>
              <Typography className={classes.infoValue}>
                {foundCount}
              </Typography>
            </Box>
          </Box>

          <Box flexGrow={1}>
            <Box display='flex' flexDirection='column'>
              <Typography className={classes.infoTitle}>
                <Trans>Новых за сегодня</Trans>
              </Typography>
              <Typography className={classes.infoValue}>{newCount}</Typography>
            </Box>
          </Box>
        </Box>
      </BoxPlate>
    );
  };

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab as Tab);
  };

  const handleClickRow = (check: Check) => {
    // replaceAll is not available in Safari
    const snakeCaseName = check.sensor.name.replace(/-/g, '_');
    mixpanel.people.increment(`open_${snakeCaseName}_page_count`);
    mixpanel.track(`open_${snakeCaseName}_page`);
    mixpanel.track('open_sensor_page', {sensor_name: snakeCaseName});

    const link = checkHelpers.detailsPageLink(
      check.sensor.name,
      t(sensorName(check.sensor.name)),
    );
    navigate(link);
  };

  const sensorsOptions = () => {
    return validChecks
      .concat(excludedChecks)
      .concat(needRegionsChecks)
      .map(v => v.sensor.name)
      .map(v => ({id: v, title: t(sensorName(v))}))
      .sort((a, b) => {
        if (a.title > b.title) return 1;
        if (a.title === b.title) return 0;
        return -1;
      });
  };

  const handleChangeAutocomplete = (value: sensorOption | null) => {
    if (value === null) return;

    mixpanel.track('open_sensor_category_autocomplete_variant', {
      sensor: value.id,
    });
    navigate(checkHelpers.detailsPageLink(value.id, value.title));
  };

  const handleStartTypingAutocomplete = () => {
    mixpanel.track('start_typing_category_autocomplete_variant');
  };

  return (
    <>
      <BackHeader />

      <Box mt={8}>
        <Typography variant='h1'>{t(category.title)}</Typography>
      </Box>

      <Box mt={6}>
        <Typography variant='body1'>{t(category.description)}</Typography>
      </Box>

      <Box mt={6}>{renderInfoPlate()}</Box>

      <Box mt={6}>
        <Autocomplete
          placeholder={t('Поиск по сенсорам')}
          options={sensorsOptions()}
          onChange={handleChangeAutocomplete}
          onStartTyping={handleStartTypingAutocomplete}
        />
      </Box>

      <Box mt={8}>
        <IssueTabs activeTab={activeTab} onChange={handleChangeTab} />

        <Box mt={6}>
          {sortChecks(validChecks, t).map((v, i) => (
            <Row key={i} check={v} onClick={handleClickRow} />
          ))}

          {sortChecks(excludedChecks, t).map((v, i) => (
            <ExcludedRow key={i} check={v} onClick={handleClickRow} />
          ))}

          {sortChecks(needRegionsChecks, t).map((v, i) => (
            <NeedRegionsRow key={i} check={v} onClick={handleClickRow} />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Category;
