/** @format */
import {Box, Typography} from '@mui/material';

import BaseTextField from 'components/inputs/BaseTextField';
import EmailField from 'components/inputs/EmailField';
import LegacyTextField from 'components/inputs/LegacyTextField';
import MediumTextField from 'components/inputs/MediumTextField';
import PasswordField from 'components/inputs/PasswordField';
import PhoneNumberField from 'components/inputs/PhoneNumberField';

const Inputs = () => {
  return (
    <Box>
      <Box mt={4}>
        <Typography>MediumTextField</Typography>
        <MediumTextField />
      </Box>
      <Box mt={4}>
        <Typography>LegacyTextField</Typography>
        <LegacyTextField />
      </Box>
      <Box mt={4}>
        <Typography>BaseTextField</Typography>
        <BaseTextField />
      </Box>
      <Box mt={4}>
        <Typography>PasswordField</Typography>
        <PasswordField />
      </Box>
      <Box mt={4}>
        <Typography>PhoneNumberField</Typography>
        <PhoneNumberField />
      </Box>
      <Box mt={4}>
        <Typography>EmailField</Typography>
        <EmailField />
      </Box>
    </Box>
  );
};

export default Inputs;
