/** @format */
import {Box, Typography} from '@mui/material';

import OutlinedLargeButton from 'components/buttons/OutlinedLargeButton';
import OutlinedSmallButton from 'components/buttons/OutlinedSmallButton';
import PrimaryLargeButton from 'components/buttons/PrimaryLargeButton';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import PrimarySmallButton from 'components/buttons/PrimarySmallButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import TertiaryMediumButton from 'components/buttons/TertiaryMediumButton';
import TransparentMediumButton from 'components/buttons/TransparentMediumButton';
import TransparentSmallButton from 'components/buttons/TransparentSmallButton';

const Buttons = () => {
  const handleClick = () => {
    console.log('click');
  };

  return (
    <Box>
      <Box mt={4}>
        <Typography>OutlinedLargeButton</Typography>
        <OutlinedLargeButton onClick={handleClick}>
          Click me
        </OutlinedLargeButton>
      </Box>
      <Box mt={4}>
        <Typography>OutlinedSmallButton</Typography>
        <OutlinedSmallButton onClick={handleClick}>
          Click me
        </OutlinedSmallButton>
      </Box>
      <Box mt={4}>
        <Typography>PrimaryLargeButton</Typography>
        <PrimaryLargeButton onClick={handleClick}>
          Click me
        </PrimaryLargeButton>
      </Box>
      <Box mt={4}>
        <Typography>PrimaryMediumButton</Typography>
        <PrimaryMediumButton onClick={handleClick}>
          Click me
        </PrimaryMediumButton>
      </Box>
      <Box mt={4}>
        <Typography>PrimarySmallButton</Typography>
        <PrimarySmallButton onClick={handleClick}>
          Click me
        </PrimarySmallButton>
      </Box>
      <Box mt={4}>
        <Typography>SecondaryMediumButton</Typography>
        <SecondaryMediumButton onClick={handleClick}>
          Click me
        </SecondaryMediumButton>
      </Box>
      <Box mt={4}>
        <Typography>TertiaryMediumButton</Typography>
        <TertiaryMediumButton onClick={handleClick}>
          Click me
        </TertiaryMediumButton>
      </Box>
      <Box mt={4}>
        <Typography>TransparentMediumButton</Typography>
        <TransparentMediumButton onClick={handleClick}>
          Click me
        </TransparentMediumButton>
      </Box>
      <Box mt={4}>
        <Typography>TransparentSmallButton</Typography>
        <TransparentSmallButton onClick={handleClick}>
          Click me
        </TransparentSmallButton>
      </Box>
    </Box>
  );
};

export default Buttons;
