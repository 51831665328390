/** @format */
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {Box, Typography} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {format, parseISO} from 'date-fns';

import BoxPlate from 'components/plates/BoxPlate';
import Legend from './Legend';
import miscHelpers from 'helpers/miscHelpers';
import type {GraphCheck} from 'types';

const OLD_COLOR = '#6263E8';
const NEW_COLOR = '#EB8283';
const FIXED_COLOR = '#FFFFFF';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bluePlateText: {
      fontWeight: 500,
    },
    tooltip: {
      alignSelf: 'flex-start',
      backgroundColor: '#1A1A1A',
      borderRadius: 5,
      color: theme.palette.text.primary,
      display: 'flex',
      flexDirection: 'column',
      fontSize: 12,
      fontWeight: 400,
      height: 110,
      justifyContent: 'space-between',
      lineHeight: '20px',
      padding: '8px 16px',
      width: 190,
    },
  }),
);

type Props = {
  errorsAvgDays: number;
  graphChecks: GraphCheck[];
  warningsAvgDays: number;
};

const CheckGraph = (props: Props) => {
  const classes = useStyles();

  const renderBluePlate = () => {
    const errorsAvgDaysText = miscHelpers.inflectNoun(
      props.errorsAvgDays,
      'день',
      'дня',
      'дней',
    );

    const warningsAvgDaysText = miscHelpers.inflectNoun(
      props.warningsAvgDays,
      'день',
      'дня',
      'дней',
    );

    return (
      <Box mt={6}>
        <BoxPlate backgroundColor='#6263E8'>
          <Box>
            <Typography
              className={classes.bluePlateText}
              display='inline'
              variant='body1'
            >
              {'Cредний показатель исправления ошибок —'}
            </Typography>{' '}
            <Typography
              className={classes.bluePlateText}
              display='inline'
              variant='body1'
            >
              {`${props.errorsAvgDays} ${errorsAvgDaysText}`}
            </Typography>
            <Typography
              className={classes.bluePlateText}
              display='inline'
              variant='body1'
            >
              {', замечаний —'}
            </Typography>{' '}
            <Typography
              className={classes.bluePlateText}
              display='inline'
              variant='body1'
            >
              {`${props.warningsAvgDays} ${warningsAvgDaysText}`}
            </Typography>
          </Box>
        </BoxPlate>
      </Box>
    );
  };

  const renderGraph = () => {
    const formatDateValue = (dateValue: string) => {
      const date = parseISO(dateValue);
      return format(date, 'dd.MM');
    };

    return (
      <Box mt={6}>
        <BoxPlate>
          <Box height={300} pb={3} pr={3} pt={3}>
            <ResponsiveContainer height='100%'>
              <BarChart data={props.graphChecks} margin={{left: 10, right: 10}}>
                <XAxis
                  axisLine={false}
                  dataKey='collectedOn'
                  interval='preserveStartEnd'
                  tickLine={false}
                  tick={<CustomXTick formatDateValue={formatDateValue} />}
                />
                <YAxis
                  axisLine={false}
                  tick={<CustomYTick />}
                  tickLine={false}
                />

                <CartesianGrid stroke='#4D4D4D' vertical={false} />

                <Tooltip
                  content={
                    <CustomTooltip
                      formatDateValue={formatDateValue}
                      showFixed={true}
                    />
                  }
                  cursor={false}
                />

                <Bar
                  barSize={3}
                  dataKey='old'
                  radius={[8, 8, 8, 8]}
                  stackId='found'
                >
                  {props.graphChecks.map((_, index: number) => {
                    return <Cell key={index} fill={OLD_COLOR} />;
                  })}
                </Bar>

                <Bar
                  barSize={3}
                  dataKey='new'
                  radius={[8, 8, 8, 8]}
                  stackId='found'
                >
                  {props.graphChecks.map((_, index: number) => {
                    return <Cell key={index} fill={NEW_COLOR} />;
                  })}
                </Bar>

                <Bar barSize={3} dataKey='fixed' radius={[8, 8, 8, 8]}>
                  {props.graphChecks.map((_, index: number) => {
                    return <Cell key={index} fill={FIXED_COLOR} />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </BoxPlate>
      </Box>
    );
  };

  const renderLegend = () => {
    const items = [
      {
        text: 'старые',
        backgroundColor: OLD_COLOR,
      },
      {
        text: 'новые',
        backgroundColor: NEW_COLOR,
      },
      {
        text: 'исправлено',
        backgroundColor: FIXED_COLOR,
      },
    ];

    return (
      <Box mt={3}>
        <Legend items={items} smGridSize={2} xsGridSize={3} />
      </Box>
    );
  };

  return (
    <>
      {renderBluePlate()}
      {renderGraph()}
      {renderLegend()}
    </>
  );
};

const CustomXTick = (props: any) => {
  return (
    <text
      dx={-10}
      dy={10}
      fill='#FFFFFF'
      fontSize={10}
      fontWeight='400'
      x={props.x}
      y={props.y}
    >
      {props.formatDateValue(props.payload.value)}
    </text>
  );
};

const CustomYTick = (props: any) => {
  return (
    <text
      dx={-40}
      fill='#FFFFFF'
      fontSize={10}
      fontWeight='400'
      x={props.x}
      y={props.y}
    >
      {props.payload.value}
    </text>
  );
};

const CustomTooltip = (props: any) => {
  const classes = useStyles();

  if (!props.active) return null;
  if (!props.payload) return null;
  if (props.payload.length === 0) return null;

  const {collectedOn} = props.payload[0].payload;

  return (
    <Box className={classes.tooltip}>
      <Box>
        <Typography style={{color: OLD_COLOR}}>
          {props.payload[0].payload.old}
        </Typography>
        <Typography style={{color: NEW_COLOR}}>
          {props.payload[1].payload.new}
        </Typography>
        <Typography style={{color: FIXED_COLOR}}>
          {props.payload[2].payload.fixed}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography>{props.formatDateValue(collectedOn)}</Typography>
      </Box>
    </Box>
  );
};

export default CheckGraph;
