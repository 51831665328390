/** @format */

import React, {useEffect, useState} from 'react';

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import clsx from 'clsx';
import {Box, Grid, LinearProgress, Link, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TuneIcon from '@mui/icons-material/Tune';

import AdTypePlatforms from 'components/icons/AdTypePlatforms';
import Breadcrumbs from 'components/Breadcrumbs';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import SecondarySelect from 'components/selects/SecondarySelect';

import adTypeHelpers from 'helpers/adTypeHelpers';
import routerHelpers from 'helpers/routerHelpers';

import SensorsModal from './SensorsModal';
import RadioRow from './RadioRow';
import Deleted from './Deleted';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($specialistId: ID!, $notificationId: ID) {
    currentUser {
      roles
      specialist(id: $specialistId) {
        email
        uuid
      }
      notification(id: $notificationId) {
        id
        channelType
        adTypes
        monitorAdTypes
        emailChannel {
          email
        }
        telegramChannel {
          xeniooUserUuid
        }
        adTypeSensors {
          context
          target
        }
        company {
          id
        }
      }
      companies {
        id
        name
      }
      sensors {
        id
        name
        platform
        isError
      }
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification(
    $specialistId: ID!
    $companyId: ID!
    $channelType: AccountsNotificationChannelType!
    $adTypes: [YalperAdType!]!
    $emailChannel: AccountsEmailChannelInput!
    $telegramChannel: AccountsTelegramChannelInput!
    $adTypeSensors: AccountsAdTypeSensorsInput!
    $monitorAdTypes: [YalperAdType!]
  ) {
    createNotification(
      specialistId: $specialistId
      companyId: $companyId
      channelType: $channelType
      adTypes: $adTypes
      emailChannel: $emailChannel
      telegramChannel: $telegramChannel
      adTypeSensors: $adTypeSensors
      monitorAdTypes: $monitorAdTypes
    ) {
      id
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification(
    $id: ID!
    $adTypes: [YalperAdType!]!
    $emailChannel: AccountsEmailChannelInput!
    $adTypeSensors: AccountsAdTypeSensorsInput!
    $monitorAdTypes: [YalperAdType!]
  ) {
    updateNotification(
      id: $id
      adTypes: $adTypes
      emailChannel: $emailChannel
      adTypeSensors: $adTypeSensors
      monitorAdTypes: $monitorAdTypes
    ) {
      id
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id)
  }
`;

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 20,
    fontWeight: 'normal',
    lineHeight: '23px',
    marginBottom: theme.spacing(4),
  },
  text: {
    fontSize: 16,
    fontWeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '18px',
    },
    [theme.breakpoints.up('sm')]: {
      lineHeight: '20px',
    },
  },
  link: {
    color: '#8C8D8D',
    cursor: 'pointer',
    textDecoration: 'underline',
    verticalAlign: 'baseline',
  },
  hint: {
    color: '#8C8D8D',
  },
  selectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(4),
    marginRight: 0,
    borderRadius: '8px',
    background: '#202020',
    '&:last-of-type': {
      marginRight: '0',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(50% - 4px)',
      marginRight: theme.spacing(2),
      '&:last-of-type': {
        marginTop: theme.spacing(2),
      },
    },
  },
  row: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(4),
    borderRadius: '8px',
    background: '#202020',
    '&:last-of-type': {
      marginTop: 0,
    },
  },
  tgBotTitle: {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.131px',
  },
  hintNew: {
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.131px',
  },
  linkNew: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 8),
    borderRadius: '8px',
    background: '#2B2B2B',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(4),
      justifyContent: 'center',
    },
  },
  selectTitle: {
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.131px',
  },
  selectItem: {
    marginTop: theme.spacing(4),
    '& .MuiSelect-selectMenu': {
      paddingLeft: theme.spacing(12),
      borderRadius: '8px',
      border: '1px solid #2B2B2B',
      background: 'transparent',
      color: '#FFF',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.131px',
    },
    '& .MuiSelect-icon': {
      color: '#fff',
    },
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  actionButtonIcon: {
    marginRight: theme.spacing(2),
  },
  adTypesRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    background: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  },
  adTypesTitle: {
    display: 'flex',
    alignItems: 'center',
    color: '#F1F1F1',
    fontSize: theme.spacing(5),
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '0.169px',
  },
  adTypesSettingsLink: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    background: '#2B2B2B',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(0),
      justifyContent: 'center',
    },
  },
  adTypesSettingsLinkIcon: {
    marginRight: theme.spacing(2),
  },
  adTypesPlatforms: {
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    background: '#2B2B2B',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      right: '-16px',
    },
  },
}));

const breadcrumbs = [
  {
    href: '/home/settings',
    title: <Trans>Настройки</Trans>,
  },
  {
    href: '/home/settings/specialists',
    title: <Trans>Уведомления</Trans>,
  },
  {
    href: '',
    title: <Trans>Управление</Trans>,
  },
];

export const NotificationFormNew = () => {
  const searchParams = routerHelpers.useSearchParams();
  const specialistId = searchParams.get('specialistId');
  const id = searchParams.get('id');

  const {t} = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  const [state, setState] = useState({
    companyId: null,
    channelType: 'EMAIL',
    adTypes: [],
    monitorAdTypes: [],
    email: '',
  });

  const [deleted, setDeleted] = useState(false);
  const [sensorsModalsDispay, setSensorsModalDisplay] = useState({});
  const [adTypeSelectedSensors, setAdTypeSelectedSensors] = useState({});

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {specialistId, notificationId: id},
  });

  const [createNotification, {loading: createNotificationLoading}] =
    useMutation(CREATE_NOTIFICATION);
  const [updateNotification, {loading: updateNotificationLoading}] =
    useMutation(UPDATE_NOTIFICATION);
  const [deleteNotification, {loading: deleteNotificationLoading}] =
    useMutation(DELETE_NOTIFICATION);

  useEffect(() => {
    if (!data) return;

    if (data.currentUser.notification) {
      const {company, channelType, adTypes, monitorAdTypes, emailChannel} =
        data.currentUser.notification;

      setState({
        companyId: company.id,
        channelType,
        adTypes,
        monitorAdTypes,
        email: emailChannel.email || '',
      });
    } else {
      const {companies} = data.currentUser;

      setState({
        companyId: companies[0]?.id || null,
        channelType: 'EMAIL',
        adTypes: [],
        monitorAdTypes: [],
        email: data.currentUser.specialist.email,
      });
    }

    const initialAdTypeSelectedSensors = {};

    adTypeHelpers.allAdTypes().forEach(v => {
      const platforms = adTypeHelpers.platformsFromAdType(v);
      let storedSensors = [];

      if (data.currentUser.notification) {
        storedSensors =
          data.currentUser.notification.adTypeSensors[v.toLowerCase()];
      }

      const isSensorChecked = name => {
        return storedSensors.length === 0 || storedSensors.includes(name);
      };

      const initialSelectedState = R.pipe(
        R.filter(s => platforms.includes(s.platform)),
        R.map(s => s.name),
        R.uniq,
        R.map(name => [name, isSensorChecked(name)]),
        R.fromPairs,
      )(data.currentUser.sensors);

      initialAdTypeSelectedSensors[v] = initialSelectedState;
    });
    setAdTypeSelectedSensors(initialAdTypeSelectedSensors);
  }, [data]);

  if (deleted) return <Deleted email={state.email} />;

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {notification, roles} = data.currentUser;

  const xeniooUserUuid = () => {
    if (data.currentUser.notification) {
      return data.currentUser.notification.telegramChannel.xeniooUserUuid || '';
    } else {
      return data.currentUser.specialist.uuid;
    }
  };

  const renderChannelTypeSelect = () => {
    const handleSelect = channelType => {
      setState({
        ...state,
        channelType,
        email: channelType === 'EMAIL' ? data.currentUser.specialist.email : '',
      });
    };

    const options = [
      {value: 'EMAIL', text: t('Email', 'Email')},
      {value: 'TELEGRAM', text: t('Telegram', 'Telegram')},
    ];

    return (
      <Box mt={2} className={classes.selectWrapper}>
        <Box className={classes.selectTitle}>
          <Trans>Выберите канал</Trans>
        </Box>

        <SecondarySelect
          selectClassName={classes.selectItem}
          disabled={Boolean(notification)}
          options={options}
          value={state.channelType}
          onSelect={handleSelect}
          icon={<SendIcon fontSize='small' htmlColor='#8B8B8B' />}
        />
      </Box>
    );
  };

  const renderCompanySelect = () => {
    const handleSelect = companyId => {
      setState({...state, companyId});
    };

    const options = data.currentUser.companies.map(v => ({
      value: v.id,
      text: v.name,
    }));

    return (
      <Box mt={4} className={classes.selectWrapper}>
        <Box className={classes.selectTitle}>
          <Trans>Выберите проект</Trans>
        </Box>

        <SecondarySelect
          selectClassName={classes.selectItem}
          options={options}
          disabled={Boolean(notification)}
          value={state.companyId || ''}
          aria-label='company-select-input'
          onSelect={handleSelect}
          icon={<DashboardIcon fontSize='small' htmlColor='#8B8B8B' />}
        />
      </Box>
    );
  };

  const renderAdTypeRadios = () => {
    const selectedAdType = adType => state.adTypes.includes(adType);
    const selectedMonitorAdType = adType =>
      state.monitorAdTypes.includes(adType);

    const handleSelectAdType = adType => () => {
      const adTypes = R.includes(adType, state.adTypes)
        ? R.without([adType], state.adTypes)
        : R.concat(state.adTypes, [adType]);

      setState({...state, adTypes});
    };

    const handleSelectMonitorAdType = adType => () => {
      const adTypes = R.includes(adType, state.monitorAdTypes)
        ? R.without([adType], state.monitorAdTypes)
        : R.concat(state.monitorAdTypes, [adType]);

      setState({...state, monitorAdTypes: adTypes});
    };

    const handleShowModal = adType => {
      const newState = {...sensorsModalsDispay};
      newState[adType] = true;
      setSensorsModalDisplay(newState);
    };

    const handleCloseModal = adType => {
      const newState = {...sensorsModalsDispay};
      newState[adType] = false;
      setSensorsModalDisplay(newState);
    };

    const handleSubmit = (adType, newValue) => {
      R.toPairs(newValue).forEach(([k, v]) => {
        adTypeSelectedSensors[adType][k] = v;
      });

      setAdTypeSelectedSensors(adTypeSelectedSensors);
      handleCloseModal(adType);
    };

    const text = v => {
      if (v === 'CONTEXT') return t('Контекст', 'Контекст');
      if (v === 'TARGET') return t('Таргет', 'Таргет');

      return null;
    };

    return (
      <Box mt={4}>
        <Grid container spacing={2}>
          {adTypeHelpers.allAdTypes().map((v, i) => (
            <React.Fragment key={i}>
              <Grid item xs={12}>
                <Box
                  className={classes.adTypesRow}
                  display={'flex'}
                  flexWrap={'wrap'}
                >
                  <RadioRow
                    selected={selectedAdType(v)}
                    onSelect={handleSelectAdType(v)}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      className={classes.adTypesRow}
                      flexWrap='wrap'
                    >
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        flexGrow={1}
                      >
                        <Typography className={classes.adTypesTitle}>
                          {text(v)}
                        </Typography>

                        <Box className={classes.adTypesPlatforms}>
                          <AdTypePlatforms adTypes={[v]} size={20} />
                        </Box>
                      </Box>
                    </Box>
                  </RadioRow>
                  {selectedAdType(v) && (
                    <Box
                      className={clsx(
                        classes.text,
                        classes.adTypesSettingsLink,
                      )}
                      onClick={() => handleShowModal(v)}
                    >
                      <TuneIcon
                        className={classes.adTypesSettingsLinkIcon}
                        htmlColor='#fff'
                        fontSize='small'
                      />

                      <Trans>Настроить</Trans>
                    </Box>
                  )}
                </Box>
                {roles.includes('REVIEWER') && (
                  <RadioRow
                    selected={selectedMonitorAdType(v)}
                    mt={3}
                    onSelect={handleSelectMonitorAdType(v)}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {sensorsModalsDispay[v] && (
                  <SensorsModal
                    sensors={data.currentUser.sensors}
                    selectedSensors={adTypeSelectedSensors[v]}
                    adType={v}
                    onClose={() => handleCloseModal(v)}
                    onSubmit={s => handleSubmit(v, s)}
                  />
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    );
  };

  const renderButtons = () => {
    const selectedSensorNames = () => {
      const result = {};

      R.pipe(
        R.toPairs,
        R.forEach(([adType, values]) => {
          const isAllSelected = R.all(v => v[1], R.toPairs(values));
          let sensorNames = [];

          if (!isAllSelected) {
            sensorNames = R.pipe(
              R.toPairs,
              R.filter(v => v[1]),
              R.map(v => v[0]),
            )(values);
          }
          result[adType.toLowerCase()] = sensorNames;
        }),
      )(adTypeSelectedSensors);

      return result;
    };

    const handleSave = async () => {
      try {
        if (notification) {
          await updateNotification({
            variables: {
              id,
              adTypes: state.adTypes,
              emailChannel: {email: state.email},
              adTypeSensors: selectedSensorNames(),
              monitorAdTypes: state.monitorAdTypes,
            },
          });
        } else {
          await createNotification({
            variables: {
              specialistId,
              companyId: state.companyId,
              channelType: state.channelType,
              adTypes: state.adTypes,
              emailChannel: {email: state.email},
              telegramChannel: {xeniooUserUuid: xeniooUserUuid()},
              adTypeSensors: selectedSensorNames(),
              monitorAdTypes: state.monitorAdTypes,
            },
          });
        }

        navigate({
          pathname: '/home/settings/notifications',
          search: `?specialistId=${specialistId}`,
        });
      } catch (e) {
        console.log(e);
      }
    };

    const handleDelete = async () => {
      await deleteNotification({variables: {id}});
      setDeleted(true);
    };

    const stateValid =
      (state.adTypes.length > 0 || state.monitorAdTypes.length > 0) &&
      (state.channelType === 'TELEGRAM' ||
        (state.channelType === 'EMAIL' && state.email));

    return (
      <Grid container>
        <Grid item xs={12}>
          <PrimaryMediumButton
            className={classes.actionButton}
            disabled={
              createNotificationLoading ||
              updateNotificationLoading ||
              !stateValid
            }
            data-testid='save-button'
            onClick={handleSave}
          >
            <SaveOutlinedIcon
              className={classes.actionButtonIcon}
              fontSize='small'
              htmlColor='#FFFFFF'
            />

            <Trans>Сохранить</Trans>
          </PrimaryMediumButton>
        </Grid>

        {notification && (
          <Grid item xs={12}>
            <Box mt={4}>
              <SecondaryMediumButton
                className={classes.actionButton}
                disabled={deleteNotificationLoading}
                onClick={handleDelete}
              >
                <Trans>Удалить</Trans>
              </SecondaryMediumButton>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderTelegramBotLink = () => {
    if (state.channelType !== 'TELEGRAM') return null;

    const botLink = `http://t.me/adsensor_notifications_bot?start=${xeniooUserUuid()}`;

    return (
      <Box
        className={classes.row}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <Box display='flex' flexDirection='column'>
          <Box>
            <Typography className={classes.tgBotTitle}>
              <Trans>Телеграм бот</Trans>
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.hintNew}>
              <Trans>
                Вы можете скопировать и отправить эту ссылку специалисту,
                который будет получать уведомления
              </Trans>
            </Typography>
          </Box>
        </Box>
        <Link className={classes.linkNew} href={botLink} target='_blank'>
          <Trans>Подключить бота</Trans>
        </Link>
      </Box>
    );
  };

  return (
    <>
      <Box mt={12} mb={10}>
        <Breadcrumbs items={breadcrumbs} />
      </Box>

      <Box mt={8} />
      <Typography className={classes.title}>
        <Trans>Настройка уведомления</Trans>
      </Typography>

      <Box display='flex' flexWrap='wrap'>
        {renderChannelTypeSelect()}
        {renderCompanySelect()}
      </Box>

      {renderTelegramBotLink()}

      <Box mt={6}>{renderAdTypeRadios()}</Box>

      <Box mt={6}>{renderButtons()}</Box>
    </>
  );
};
