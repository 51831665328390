/** @format */

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import {Box, Link, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useQuery} from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';

import ModalLoading from 'components/loading/ModalLoading';
import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import checkHelpers from 'helpers/checkHelpers';
import {sensorName} from 'helpers/sensor_names';
import {useCompanyContext} from 'contexts/CompanyContext';

const GET_CURRENT_USER = gql`
  query GetCurrentUser($adType: YalperAdType!) {
    currentUser {
      budgetScoreSensors(adType: $adType) {
        name
        isError
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  body: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '20px',
    whiteSpace: 'pre-line',
  },
  sectionTitle: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: '700',
    lineHeight: '20px',
    marginTop: theme.spacing(6),
  },
  sectionBody: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '18px',
  },
  link: {
    color: '#73A011',
    fontSize: 18,
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    textDecoration: 'underline',
  },
}));

const BudgetScoreDescription = () => {
  const navigate = useNavigate();

  const {t} = useTranslation();

  const {currentAdType} = useCompanyContext();
  const classes = useStyles();

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {adType: currentAdType},
  });

  if (loading) {
    return (
      <ModalLoading
        title={t('Пожалуйста, подождите', 'Пожалуйста, подождите')}
      />
    );
  }
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const renderLink = (sensor, i) => {
    const handleClick = () => {
      const link = checkHelpers.detailsPageLink(
        sensor.name,
        t(sensorName(sensor.name)),
      );
      navigate(link);
    };

    return (
      <Box key={i} mt={3}>
        <Link component='button' onClick={handleClick}>
          <Typography className={classes.link}>
            {t(sensorName(sensor.name))}
          </Typography>
        </Link>
      </Box>
    );
  };

  const renderErrorSensors = () => {
    const errorSensors = R.pipe(
      R.filter(v => v.isError),
      R.uniqBy(R.prop('name')),
      R.sortBy(v => t(sensorName(v.name))),
    )(data.currentUser.budgetScoreSensors);

    return (
      <>
        <Typography className={classes.sectionTitle}>
          <Trans>Критичные сенсоры</Trans>
        </Typography>

        {errorSensors.map((v, i) => renderLink(v, i))}
      </>
    );
  };

  const renderWarningSensors = () => {
    const warningSensors = R.pipe(
      R.filter(v => !v.isError),
      R.uniqBy(R.prop('name')),
      R.sortBy(v => t(sensorName(v.name))),
    )(data.currentUser.budgetScoreSensors);

    return (
      <>
        <Typography className={classes.sectionTitle}>
          <Trans>Минорные сенсоры</Trans>
        </Typography>

        <Box mt={2}>
          <Typography className={classes.sectionBody}>
            <Trans>
              Хотите отключить сенсор, сделайте это на карточке сенсора, и мы не
              будем учитывать его в общем скоре
            </Trans>
          </Typography>
        </Box>

        {warningSensors.map((v, i) => renderLink(v, i))}
      </>
    );
  };

  const renderButton = () => {
    const handleClick = () => {
      navigate(-1);
    };

    return (
      <Box mt={6}>
        <PrimaryMediumButton onClick={handleClick}>
          <Trans>Понятно</Trans>
        </PrimaryMediumButton>
      </Box>
    );
  };

  return (
    <ModalPage
      header={t('Эффективность работы бюджета', 'Эффективность работы бюджета')}
    >
      <Typography className={classes.body}>
        <Trans>
          Ваш бюджет расходуется максимально эффективно, если система не выявила
          важные рекомендации (список ниже), по которым идет расход средств.
          <br />
          <br />
          Система считает, что часть бюджета кампании, по которой сработали
          сенсоры, расходуется с пониженной эффективностью и вы потенциально
          можете терять на ней деньги.
          <br />
          <br />
          Каждый сенсор влияет со своим коэффициентом. Например, расход ссылок с
          "Несуществующими страницами" имеет коэффициент = 1. А отсутствие
          быстрых ссылок только 0.15, из расчета, что цена клика без расширений
          может быть завышена до 15% по сравнению с объявлениями, где
          присутствуют все расширения.
          <br />
          <br />
          Если кампания не тратит деньги, то выявленные рекомендации не повлияют
          на подсчитанную системой эффективность вашего бюджета.
          <br />
          <br />
          На эффективность бюджета влияют только указанные ниже проверки.
          Внесите изменения в кампании согласно рекомендациям, чтобы достичь
          показателя эффективности в 100%.
        </Trans>
      </Typography>

      {renderErrorSensors()}
      {renderWarningSensors()}
      {renderButton()}
    </ModalPage>
  );
};

export default BudgetScoreDescription;
