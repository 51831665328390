/** @format */
import {Box, Typography} from '@mui/material';
import {add} from 'date-fns';

import Spent from 'components/Dashboard/Spent';
import SpentGraph from 'components/graphs/SpendGraph';
import type {PlatformGraphStat} from 'types';

const Dashboard = () => {
  const platformStat = {
    "clicks": 1112,
    "cpc": 103.5,
    "ctr": 10.01,
    "extendedPlatform": "YANDEX",
    "impressions": 11112,
    "monthSpent": 2003584,
    "platform": "YANDEX",
    "spent": 115097,
    "state": "READY",
    "currency": "RUB",
  };

  const usdPlatformStat = {
		"clicks": 2222,
		"cpc": 17.78,
		"ctr": 10,
		"currency": "USD",
		"extendedPlatform": "GOOGLE",
		"impressions": 22222,
		"monthSpent": 567002,
		"platform": "GOOGLE",
		"spent": 39506,
		"state": "READY"
  }

  const mediaplanGood = {
    monthly: 10_000_000,
    dayly: 120_000,
  };

  const mediaplanBad = {
    monthly: 2_000_000,
    dayly: 66_000,
  };

  const graphSpent = [
    {
      date: '2022-01-01',
      currency: 'RUB',
      spent: 10000,
    },
    {
      date: '2022-01-02',
      currency: 'RUB',
      spent: 5500,
    },
    {
      date: '2022-01-03',
      currency: 'RUB',
      spent: 12456,
    },
    {
      date: '2022-01-04',
      currency: 'RUB',
      spent: 9888,
    },
    {
      date: '2022-01-05',
      currency: 'RUB',
      spent: 2888,
    },
  ] as PlatformGraphStat[];

  const usdGraphSpent = [
    {
      date: '2022-01-01',
      currency: 'USD',
      spent: 10000,
    },
    {
      date: '2022-01-02',
      currency: 'USD',
      spent: 5500,
    },
    {
      date: '2022-01-03',
      currency: 'USD',
      spent: 12456,
    },
    {
      date: '2022-01-04',
      currency: 'USD',
      spent: 9888,
    },
    {
      date: '2022-01-05',
      currency: 'USD',
      spent: 2888,
    },
  ] as PlatformGraphStat[];

  const yesterday = add(new Date(), {days: -1});

  return (
    <Box>
      <Box mt={5}>
        <Typography>RUB</Typography>
        <Box>
          <Spent
            platformStat={platformStat}
            mediaplan={mediaplanGood}
            date={yesterday}
          />
        </Box>
        <Box mt={5}>
          <Spent
            platformStat={platformStat}
            mediaplan={mediaplanBad}
            date={yesterday}
          />
        </Box>
        <Box mt={5}>
          <Spent platformStat={platformStat} mediaplan={null} date={yesterday}/>
        </Box>
      </Box>
      <Box mt={5}>
        <Typography>USD</Typography>
        <Box>
          <Spent
            platformStat={usdPlatformStat}
            mediaplan={mediaplanGood}
            date={yesterday}
          />
        </Box>
        <Box mt={5}>
          <Spent
            platformStat={usdPlatformStat}
            mediaplan={mediaplanBad}
            date={yesterday}
          />
        </Box>
        <Box mt={5}>
          <Spent
            platformStat={usdPlatformStat}
            mediaplan={null}
            date={yesterday}
          />
        </Box>
      </Box>
      <Box mt={5}>
        <Typography>RUB</Typography>
        <Box mt={5}>
          <SpentGraph platformGraphStats={graphSpent} />
        </Box>
        <Box mt={5}>
          <SpentGraph platformGraphStats={graphSpent} planValue={5000}/>
        </Box>
      </Box>
      <Box mt={5}>
        <Typography>USD</Typography>
        <Box mt={5}>
          <SpentGraph platformGraphStats={usdGraphSpent} />
        </Box>
        <Box mt={5}>
          <SpentGraph platformGraphStats={usdGraphSpent} planValue={5000}/>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
