/** @format */
import {ChangeEvent, useState} from 'react';

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import {Box, Grid, IconButton, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {useNavigate} from 'react-router-dom';

import AutocompletePicker from 'components/pickers/AutocompletePicker';
import MediumTextField from 'components/inputs/MediumTextField';
import ModalLoading from 'components/loading/ModalLoading';
import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import removeImage from 'images/remove.png';
import routerHelpers from 'helpers/routerHelpers';

type geo = {
  id: number;
  name: string;
  parentName: string;
};

type getGeoGroups = {
  currentUser: {
    currentCompany: {
      geoGroups: {
        name: string;
        geos: geo[];
      }[];
    };
  };
  allGeos: geo[];
};

const GET_GEO_GROUPS = gql`
  query GetGeos {
    currentUser {
      currentCompany {
        geoGroups {
          name
          geos {
            id
            name
            parentName
          }
        }
      }
    }
    allGeos {
      id
      name
      parentName
    }
  }
`;

const SAVE_GEO_GROUPS = gql`
  mutation SaveGeoGroups($geoGroups: [YalperGeoGroupInput!]!) {
    saveGeoGroups(geoGroups: $geoGroups)
  }
`;

const useStyles = makeStyles(theme =>
  createStyles({
    text: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
    },
    tagText: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
    },
    removeIcon: {
      padding: theme.spacing(1),
    },
  }),
);

const GeoGroups = () => {
  const classes = useStyles();
  const [selectedGeos, setSelectedGeos] = useState<geo[]>([]);
  const [name, setName] = useState('');

  const {t} = useTranslation();

  const navigate = useNavigate();

  const searchParams = routerHelpers.useSearchParams();
  const humanName = searchParams.get('humanName');

  const {loading, error, data} = useQuery<getGeoGroups>(GET_GEO_GROUPS);
  const [saveGeoGroups, {loading: saveGeoGroupsLoading}] =
    useMutation(SAVE_GEO_GROUPS);

  if (loading || !data) return <ModalLoading title='Пожалуйста, подождите' />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {allGeos} = data;
  const {geoGroups} = data.currentUser.currentCompany;

  const renderPicker = () => {
    const handleClose = (newSelectedGeos: geo[]) => {
      const geos = newSelectedGeos.map(v => ({
        id: v.id,
        name: v.name,
        parentName: v.parentName,
      }));
      setSelectedGeos(geos);
    };

    return (
      <Box mt={4}>
        <AutocompletePicker
          idField='id'
          labelField='name'
          options={allGeos}
          placeholder={t(
            'Выберите регионы, в которых работаете',
            'Выберите регионы, в которых работаете',
          )}
          placeholderFocused={t(
            'Введите название региона',
            'Введите название региона',
          )}
          value={selectedGeos}
          onClose={handleClose}
        />
      </Box>
    );
  };

  const renderTag = (geo: geo) => {
    const handleRemove = () => {
      const newSelectedGeos = selectedGeos.filter(v => v.id !== geo.id);
      setSelectedGeos(newSelectedGeos);
    };

    return (
      (<Box key={geo.id} alignItems='center' display='flex' mr={4}>
        <Typography className={classes.tagText}>{geo.name}</Typography>
        <IconButton className={classes.removeIcon} onClick={handleRemove} size="large">
          <img alt='Remove' height={18} src={removeImage} width={18} />
        </IconButton>
      </Box>)
    );
  };

  const handleChange = (event: ChangeEvent<{value: string}>) => {
    const {value} = event.target;
    setName(value);
  };

  const sortedSelectedGeos = R.sortBy<geo>(v => v.name, selectedGeos);

  const renderButtons = () => {
    const handleSave = async () => {
      const newGeoGroups = geoGroups.map(v => ({
        name: v.name,
        geos: v.geos.map(vv => ({
          id: vv.id,
          name: vv.name,
          parentName: vv.parentName,
        })),
      }));
      newGeoGroups.push({name: name, geos: selectedGeos});
      await saveGeoGroups({variables: {geoGroups: newGeoGroups}});
      navigate(-1);
    };

    const isFilled = name.trim().length > 0 && selectedGeos.length > 0;

    return (
      <Box mt={16}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <PrimaryMediumButton
              disabled={saveGeoGroupsLoading || !isFilled}
              fullWidth
              onClick={handleSave}
            >
              <Trans>Сохранить</Trans>
            </PrimaryMediumButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <ModalPage header={humanName}>
      <Typography className={classes.text}>
        <Trans>
          Укажите фразу с названием региона из названия кампаний и добавьте
          относящиеся регионы из справочника
        </Trans>
      </Typography>

      <Box mt={8}>
        <MediumTextField
          placeholder={t('Фраза в названии', 'Фраза в названии')}
          value={name}
          inputProps={{name: 'name'}}
          myProps={{hint: '', placeholderFocused: ''}}
          onChange={handleChange}
        />
        {renderPicker()}
      </Box>

      <Box display='flex' flexWrap='wrap' mt={4}>
        {sortedSelectedGeos.map(v => renderTag(v))}
      </Box>

      {renderButtons()}
    </ModalPage>
  );
};

export default GeoGroups;
