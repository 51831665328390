/** @format */

import {Box, Typography} from '@mui/material';
import {Trans} from 'react-i18next';

const Finish = () => {
  return (
    <>
      <Typography variant='h1'>
        <Trans>Готово!</Trans>
      </Typography>

      <Box mt={4}>
        <Typography variant='body1'>
          <Trans>Спасибо, что справились так быстро.</Trans>
          <br />
          <br />
          <Trans>
            Мы уже запустилии проверки, через пару часов отчёт будет готов.
          </Trans>
        </Typography>
      </Box>
    </>
  );
};

export default Finish;
