/** @format */

import {useState} from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import {Box, Container, CssBaseline, Drawer, Hidden, IconButton} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Sidebar from './Sidebar';

type Props = {
  children: React.ReactChild;
}

const DRAWER_WIDTH = 264;

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: DRAWER_WIDTH,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  },
  // use padding instead of margin because Firefox ignores marginBottom
  container: {
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(12),
    },
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: DRAWER_WIDTH,
    },
  },
  drawerPaper: {
    backgroundColor: '#3A3A3A',
    width: DRAWER_WIDTH,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  menu: {
    position: 'relative',
  },
}));

const GuestWizardLayout = (props: Props) => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderDrawer = () => {
    return (
      (<nav className={classes.drawer}>
        <Hidden mdUp>
          <Drawer
            classes={{paper: classes.drawerPaper}}
            open={isDrawerOpen}
            variant='temporary'
            ModalProps={{keepMounted: true}}
            onClose={handleToggleDrawer}
          >
            <Sidebar onToggleDrawer={handleToggleDrawer} />
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            classes={{paper: classes.drawerPaper}}
            open
            variant='permanent'
          >
            <Sidebar />
          </Drawer>
        </Hidden>
      </nav>)
    );
  };

  return (
    (<div className={classes.root}>
      <CssBaseline />
      {renderDrawer()}
      <Container className={classes.container} maxWidth='md'>
        <Box className={classes.menu}>
          <Hidden mdUp>
            <Box>
              <IconButton color='inherit' edge='start' onClick={handleToggleDrawer} size="large">
                <MenuIcon />
              </IconButton>
            </Box>
          </Hidden>
        </Box>
        {props.children}
      </Container>
    </div>)
  );
};

export default GuestWizardLayout;
