/** @format */

import {useState} from 'react';

import Alert from '@mui/material/Alert';
import {Box, CircularProgress} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {gql, useQuery} from '@apollo/client';

import Graphs from './Graphs';
import PickerBox from './PickerBox';
import PlatformTabs from 'components/tabs/PlatformTabs';
import PrimarySmallButton from 'components/buttons/PrimarySmallButton';
import SecondarySelect from 'components/selects/SecondarySelect';
import TransparentSmallButton from 'components/buttons/TransparentSmallButton';
import adTypeHelpers from 'helpers/adTypeHelpers';
import type {AdType, ExtendedPlatform, Platform, PlatformType} from 'types';
import {useCompanyContext} from 'contexts/CompanyContext';

const AD_TYPES: AdType[] = ['CONTEXT', 'TARGET'];

const SEARCH_NETWORK_PLATFORMS = ['GOOGLE', 'YANDEX'];

const PLATFORM_TYPE_HUMAN_NAMES = {
  ALL: 'Все',
  SEARCH: 'Поиск',
  NETWORK: 'Сети',
};

const AD_TYPE_HUMAN_NAMES: {[adType in AdType]: string} = {
  CONTEXT: 'Контекст',
  TARGET: 'Таргет',
};

const GET_CURRENT_USER = gql`
  query GetCurrentUser($companyId: ID) {
    currentUser {
      companies {
        id
        name
      }
      company(id: $companyId) {
        id
        uuid
        name
        tokens {
          id
          lastError
          provider
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      // Setting height is required for onboarding scrolling to work correctly
      [theme.breakpoints.down('sm')]: {
        height: 237,
      },
      [theme.breakpoints.up('sm')]: {
        height: 233,
      },
    },
  }),
);

type Company = {
  id: string;
  name: string;
};

const Stats = () => {
  const {currentAdType, currentCompany, switchAdType, switchCompany} =
    useCompanyContext();
  const classes = useStyles();

  const [companyId, setCompanyId] = useState<string>(currentCompany.id);
  const [adType, setAdType] = useState<AdType>(currentAdType);

  const [activePlatform, setActivePlatform] = useState<Platform>('ALL');
  const [activeExtendedPlatform, setActiveExtendedPlatform] =
    useState<ExtendedPlatform>('ALL');
  const [activePlatformType, setActivePlatformType] =
    useState<PlatformType>('ALL');

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {companyId},
  });

  if (loading) {
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {companies}: {companies: Company[]} = data.currentUser;

  if (companies.length === 0) {
    return <Alert severity='error'>У клиента нет проектов</Alert>;
  }

  const {company} = data.currentUser;

  if (!company) {
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  const renderCompanySelect = () => {
    const handleSelect = (value: string) => {
      setCompanyId(value);
      switchCompany(company);
    };

    const options = companies.map(v => ({value: v.id, text: v.name}));

    return (
      <SecondarySelect
        options={options}
        value={companyId || ''}
        onSelect={handleSelect}
      />
    );
  };

  const renderAdTypeSelect = () => {
    const handleSelect = (value: string) => {
      setAdType(value as AdType);
      switchAdType(value as AdType);

      setActivePlatform('ALL');
      setActiveExtendedPlatform('ALL');
      setActivePlatformType('ALL');
    };

    const options = AD_TYPES.map(v => ({
      value: v,
      text: AD_TYPE_HUMAN_NAMES[v],
    }));

    return (
      <SecondarySelect
        options={options}
        value={adType || ''}
        onSelect={handleSelect}
      />
    );
  };

  const handleChangePlatform = (platform: Platform) => {
    setActivePlatform(platform);
    setActiveExtendedPlatform(platform);
    setActivePlatformType('ALL');
  };

  const renderPlatformTypes = () => {
    if (!SEARCH_NETWORK_PLATFORMS.includes(activePlatform)) return null;

    const renderButton = (platformType: PlatformType, index: number) => {
      const handleClick = () => {
        if (platformType === 'ALL') {
          setActiveExtendedPlatform(activePlatform);
        } else {
          const newExtendedPlatform =
            `${activePlatform}_${platformType}` as ExtendedPlatform;
          setActiveExtendedPlatform(newExtendedPlatform);
        }

        setActivePlatformType(platformType);
      };

      if (platformType === activePlatformType) {
        return (
          <Box key={index} px={1}>
            <PrimarySmallButton onClick={handleClick}>
              {PLATFORM_TYPE_HUMAN_NAMES[platformType]}
            </PrimarySmallButton>
          </Box>
        );
      }

      return (
        <Box key={index} px={1}>
          <TransparentSmallButton onClick={handleClick}>
            {PLATFORM_TYPE_HUMAN_NAMES[platformType]}
          </TransparentSmallButton>
        </Box>
      );
    };

    return (
      <Box display='flex' mx={-1}>
        {(['ALL', 'SEARCH', 'NETWORK'] as PlatformType[]).map((v, i) =>
          renderButton(v, i),
        )}
      </Box>
    );
  };

  const isAdTypeConnected = adTypeHelpers.isAdTypeConnected(
    company.tokens,
    adType,
  );
  const allPlatforms = adTypeHelpers
    .platformsFromAdType(adType)
    .concat(['ALL']) as Platform[];

  return (
    <>
      <PickerBox title='Проект'>{renderCompanySelect()}</PickerBox>
      <Box mt={1}>
        <PickerBox title='Тип рекламы'>{renderAdTypeSelect()}</PickerBox>
      </Box>

      {isAdTypeConnected && (
        <>
          <Box mt={5}>
            <PlatformTabs
              activePlatform={activePlatform}
              allPlatforms={allPlatforms}
              onChange={handleChangePlatform}
            />
          </Box>

          <Box mt={6}>{renderPlatformTypes()}</Box>

          <Box mt={10}>
            <Graphs
              adType={adType}
              companyId={companyId}
              extendedPlatform={activeExtendedPlatform}
              platform={activePlatform}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default Stats;
