/** @format */

import {useEffect, useMemo, useState} from 'react';

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import {Box, CircularProgress, Grid, Typography} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {format, getDate, parseISO} from 'date-fns';
import {gql, useQuery} from '@apollo/client';

import MetricGraph from './graphs/MetricGraph';
import PickerBox from '../PickerBox';
import SecondarySelect from 'components/selects/SecondarySelect';
import SpendGraph from './graphs/SpendGraph';
import constants from 'helpers/constants';
import numberHelpers from 'helpers/numberHelpers';
import statsHelpers from 'helpers/statsHelpers';
import type {
  AccountGraphStat,
  AdType,
  ExtendedPlatform,
  GraphStat,
  PlatformGraphStat,
} from 'types';
import {CSVLink} from 'react-csv';

const GET_CURRENT_USER = gql`
  query GetCurrentUser($companyId: ID!, $startDate: Date!, $endDate: Date!) {
    currentUser {
      company(id: $companyId) {
        graphStats(startDate: $startDate, endDate: $endDate) {
          platform
          extendedPlatform
          date
          spent
          impressions
          clicks
          cpc
          ctr
        }
        accountGraphStats(startDate: $startDate, endDate: $endDate) {
          account
          accountName
          platform
          extendedPlatform
          date
          spent
          impressions
          clicks
          cpc
          ctr
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      height: 100,
      justifyContent: 'center',
      width: '100%',
    },
    csvLink: {
      color: theme.palette.text.primary,
    },
  }),
);

type Props = {
  adType: AdType;
  companyId: string;
  endDate: Date;
  extendedPlatform: ExtendedPlatform;
  startDate: Date;
};

const AccountStats = (props: Props) => {
  const classes = useStyles();

  const [accountId, setAccountId] = useState<string>('ALL');

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER, {
    variables: {
      companyId: props.companyId,
      startDate: format(props.startDate, 'yyyy-MM-dd'),
      endDate: format(props.endDate, 'yyyy-MM-dd'),
    },
  });

  useEffect(() => {
    // Reset current account when platform is changed
    setAccountId('ALL');
  }, [props.extendedPlatform]);

  useEffect(() => {
    refetch();
  }, [props.adType, refetch]);

  const accounts = useMemo(() => {
    if (!data) return [];
    const {accountGraphStats} = data.currentUser.company;

    return R.pipe(
      R.filter<AccountGraphStat>(
        v =>
          props.extendedPlatform === 'ALL' ||
          props.extendedPlatform === v.extendedPlatform,
      ),
      R.map<AccountGraphStat, {id: string; name: string}>(v => ({
        id: v.account,
        name: v.accountName,
      })),
      R.uniq,
      R.concat([{id: 'ALL', name: 'ALL'}]),
    )(accountGraphStats);
  }, [props.extendedPlatform, data]);

  const platformGraphStats = useMemo(() => {
    if (!data) return [];

    if (accountId === 'ALL') {
      const {graphStats} = data.currentUser.company;
      const totalStats = statsHelpers.sumGraphStats(graphStats);
      const allStats: GraphStat[] = graphStats.concat(totalStats);

      return R.pipe(
        // https://stackoverflow.com/a/60227128/3632318
        R.filter<GraphStat>(v => props.extendedPlatform === v.extendedPlatform),
        R.sortBy<GraphStat>(v => v.date),
        R.map<GraphStat, PlatformGraphStat>(v => ({
          date: v.date,
          spent: v.spent,
          impressions: v.impressions,
          clicks: v.clicks,
          cpc: v.cpc,
          ctr: v.ctr,
        })),
      )(allStats);
    }

    const {accountGraphStats} = data.currentUser.company;
    const totalStats = statsHelpers.sumAccountGraphStats(accountGraphStats);
    const allStats: AccountGraphStat[] = accountGraphStats.concat(totalStats);

    return R.pipe(
      R.filter<AccountGraphStat>(
        v =>
          v.extendedPlatform === props.extendedPlatform &&
          v.account === accountId,
      ),
      R.sortBy<AccountGraphStat>(v => v.date),
      R.map<AccountGraphStat, PlatformGraphStat>(v => ({
        date: v.date,
        spent: v.spent,
        impressions: v.impressions,
        clicks: v.clicks,
        cpc: v.cpc,
        ctr: v.ctr,
      })),
    )(allStats);
  }, [props.extendedPlatform, accountId, data]);

  if (loading) {
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const generateAccountGraphStatsCSV = () => {
    const {accountGraphStats}: {accountGraphStats: AccountGraphStat[]} =
      data.currentUser.company;

    const header = [
      'Platform',
      'Date',
      'Account ID',
      'Account Name',
      'Spent',
      'Impressions',
      'Clicks',
      'CPC',
      'CTR',
    ];

    const rows = accountGraphStats.map(v => [
      v.extendedPlatform,
      v.date,
      v.account,
      v.accountName,
      v.spent,
      v.impressions,
      v.clicks,
      v.cpc,
      v.ctr,
    ]);

    return R.concat([header], rows);
  };

  const renderAccountPicker = () => {
    const handleSelect = (value: string) => {
      setAccountId(value);
    };

    const options = accounts.map(v => {
      return {
        value: v.id,
        text:
          v.id === 'ALL' ? 'Все' : `${v.name} ${constants.EM_DASH} ID ${v.id}`,
      };
    });

    return (
      <SecondarySelect
        options={options}
        value={accountId}
        onSelect={handleSelect}
      />
    );
  };

  const renderSpendStats = () => {
    const totalSpend = R.pipe(
      R.map<PlatformGraphStat, number>(v => v.spent),
      R.sum,
      numberHelpers.formatRUB,
    )(platformGraphStats);

    const averageSpend = R.pipe(
      R.map<PlatformGraphStat, number>(v => v.spent),
      R.sum,
      R.divide(R.__, platformGraphStats.length),
      numberHelpers.formatRUB,
    )(platformGraphStats);

    const annRudStats = R.filter<PlatformGraphStat, PlatformGraphStat[]>(v => {
      const date = parseISO(v.date);
      const day = getDate(date);
      return day >= 10 && day <= 25;
    }, platformGraphStats);

    const averageAnnRudSpend = R.pipe(
      R.map<PlatformGraphStat, number>(v => v.spent),
      R.sum,
      R.divide(R.__, annRudStats.length),
      numberHelpers.formatRUB,
    )(annRudStats);

    const medianSpend = R.pipe(
      R.map<PlatformGraphStat, number>(v => v.spent),
      R.median,
      numberHelpers.formatRUB,
    )(platformGraphStats);

    const renderRow = (title: string, formattedValue: string) => (
      <>
        <Grid item xs={7}>
          <Typography variant='body1'>{title}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='body1'>{formattedValue}</Typography>
        </Grid>
      </>
    );

    return (
      <Grid container spacing={2}>
        {renderRow('Сумма расходов', totalSpend)}
        {renderRow('Средний ежедневный расход', averageSpend)}
        {renderRow(
          'Средний ежедневный расход (за числа 10-25)',
          averageAnnRudSpend,
        )}
        {renderRow('Медианный ежедневный расход', medianSpend)}
      </Grid>
    );
  };

  return (
    <>
      <Box mt={10}>
        <CSVLink
          className={classes.csvLink}
          data={generateAccountGraphStatsCSV()}
          filename='Расход и метрики.csv'
          target='_blank'
        >
          <Typography variant='body1'>
            Скачать CSV с расходом и метриками
          </Typography>
        </CSVLink>
      </Box>

      <Box mt={6}>
        <PickerBox title='Аккаунт'>{renderAccountPicker()}</PickerBox>
      </Box>

      <Box mt={10}>
        <Typography variant='h1'>Расход бюджета (с НДС)</Typography>
        <Box mt={6}>{renderSpendStats()}</Box>
        <Box mt={6}>
          <SpendGraph platformGraphStats={platformGraphStats} />
        </Box>
      </Box>

      <Box mt={10}>
        <Typography variant='h1'>Метрики</Typography>
        <Box mt={6}>
          <MetricGraph platformGraphStats={platformGraphStats} />
        </Box>
      </Box>
    </>
  );
};

export default AccountStats;
