/** @format */
import {Box} from '@mui/material';

import JuliaBot from 'components/managers/JuliaBot';

const Managers = () => {

  return (
    <Box>
      <JuliaBot />
    </Box>
  );
};

export default Managers;

