/** @format */

import type {GridSize} from '@mui/material/Grid/Grid';
import {Box, Grid, Typography} from '@mui/material';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circle: {
      borderRadius: '50%',
      minWidth: 12,
      minHeight: 12,
      marginTop: 2,
    },
    square: {
      minWidth: 12,
      minHeight: 12,
      marginTop: 2,
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '17px',
      whiteSpace: 'pre-line',
    },
  }),
);

export type LegendItemVariant = 'CIRCLE' | 'SQUARE';

type Item = {
  text: string;
  backgroundColor: string;
  variant?: LegendItemVariant;
};

type Props = {
  items: Item[];
  smGridSize: GridSize;
  xsGridSize: GridSize;
};

const Legend = (props: Props) => {
  const classes = useStyles();

  const renderItem = (item: Item, index: number) => {
    const variant = item.variant || 'CIRCLE';

    return (
      <Grid key={index} item xs={props.xsGridSize} sm={props.smGridSize}>
        <Box alignItems='flex-start' display='flex'>
          <Box
            className={variant === 'CIRCLE' ? classes.circle : classes.square}
            style={{backgroundColor: item.backgroundColor}}
          />
          <Box ml={2}>
            <Typography className={classes.text}>{item.text}</Typography>
          </Box>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      {props.items.map((v, i) => renderItem(v, i))}
    </Grid>
  );
};

export default Legend;
