/** @format */
import {Box} from '@mui/material';

import PrimaryMenu from 'components/menus/PrimaryMenu';

const Menus = () => {
  const items = [
    {id: 'variant1', text: 'variant 1'},
    {id: 'variant2', text: 'variant 2'},
  ];

  const handleSelect = (id: string) => {
    console.log('selectec item', id);
  };

  return (
    <Box>
      <PrimaryMenu
        text='choose me'
        items={items}
        onSelect={handleSelect}
      />
    </Box>
  );
};

export default Menus;

