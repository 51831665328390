/** @format */

import {useEffect, useState} from 'react';

import {styled} from '@mui/material/styles';

import {Box, Button, Grow, Typography, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useFlags} from 'launchdarkly-react-client-sdk';

const PREFIX = 'ActionBanner';

const classes = {
  container: `${PREFIX}-container`,
  containerNew: `${PREFIX}-containerNew`,
  innerNew: `${PREFIX}-innerNew`,
  text: `${PREFIX}-text`,
  button: `${PREFIX}-button`,
  textNew: `${PREFIX}-textNew`,
  buttonNew: `${PREFIX}-buttonNew`,
};

const StyledGrow = styled(Grow)(({theme}) => ({
  [`& .${classes.container}`]: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: theme.palette.action.disabledBackground,
    display: 'flex',
    minHeight: theme.spacing(16),
    position: 'relative',
    zIndex: ACTION_BANNER_Z_INDEX,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },

  [`& .${classes.containerNew}`]: {
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'stretch',
    display: 'flex',
    padding: '16px',
    zIndex: ACTION_BANNER_Z_INDEX,
    background: '#AE5658',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  },

  [`& .${classes.innerNew}`]: {
    width: '936px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },

  [`& .${classes.text}`]: {
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      lineHeight: '20px',
      marginLeft: theme.spacing(2),
    },
  },

  [`& .${classes.button}`]: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: '20px',
    minHeight: 40,
    minWidth: 135,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
  },

  [`& .${classes.textNew}`]: {
    fontSize: '16px',
    fontWeight: 700,
  },

  [`& .${classes.buttonNew}`]: {
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #FFF',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
}));

export const ACTION_BANNER_Z_INDEX = 999;

const ActionBanner = props => {
  const [open, setOpen] = useState(false);

  const {isNewVersionEnabled} = useFlags();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setTimeout(() => setOpen(true), 100);
  }, []);

  return isNewVersionEnabled ? (
    <StyledGrow in={open}>
      <Box className={classes.containerNew}>
        <Box className={classes.innerNew}>
          <Typography className={classes.textNew}>{props.text}</Typography>
          <Button
            className={classes.buttonNew}
            fullWidth={false}
            onClick={props.onClick}
          >
            {props.buttonText}
          </Button>
        </Box>
      </Box>
    </StyledGrow>
  ) : (
    <StyledGrow in={open}>
      <Box className={classes.container}>
        {!isMobile && (
          <img
            alt='Icon'
            src={
              new URL(`./images/${props.iconName}_desktop.svg`, import.meta.url)
            }
          />
        )}
        <Typography className={classes.text}>{props.text}</Typography>
        <Button
          className={classes.button}
          color='primary'
          fullWidth={false}
          size='medium'
          variant='contained'
          onClick={props.onClick}
        >
          {props.buttonText}
        </Button>
      </Box>
    </StyledGrow>
  );
};

export default ActionBanner;
