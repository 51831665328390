/** @format */

import {useMemo} from 'react';

import {
  Box,
  Container,
  CssBaseline,
  Hidden,
  Link,
  Typography,
} from '@mui/material';
import {Trans} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import routerHelpers from 'helpers/routerHelpers';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  headContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(12),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(11),
      width: 526,
    },
  },
  logo: {
    height: 34,
  },
  home: {
    height: 24,
    weight: 24,
    marginRight: theme.spacing(1),
  },
  homeLink: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.text.primary,
  },
  logoText: {
    fontSize: 20,
    fontWeight: 700,
  },
}));

const AuthLayout = props => {
  const classes = useStyles();
  const domain = useMemo(() => routerHelpers.secondLevelDomain(), []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container className={classes.headContainer}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          mb={22}
          mt={13}
        >
          <Box display='flex' alignItems='center'>
            <Hidden mdDown>
              <img
                alt='Logo'
                className={classes.logo}
                src={new URL('./images/logo.svg', import.meta.url)}
              />
            </Hidden>
          </Box>
          <Box>
            <Link href={`https://${domain}/`}>
              <Box display='flex' alignItems='center'>
                <img
                  alt='home page'
                  className={classes.home}
                  src={new URL('./images/home.png', import.meta.url)}
                />
                <Typography className={classes.homeLink}>
                  <Trans>На главную</Trans>
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
      </Container>
      <Container className={classes.container} disableGutters maxWidth='sm'>
        {props.children}
      </Container>
    </div>
  );
};

export default AuthLayout;
