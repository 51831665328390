/** @format */

import {ChangeEvent, useEffect, useState} from 'react';

import {Box, Grid, LinearProgress, Typography} from '@mui/material';
import Alert from '@mui/material/Alert';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate, useParams} from 'react-router-dom';

import MediumTextField from 'components/inputs/MediumTextField';
import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import SecondaryMultySelect from 'components/selects/SecondaryMultySelect';
import SecondarySelect from 'components/selects/SecondarySelect';
import {useMixpanelContext} from 'contexts/MixpanelContext';

interface params {
  id: string;
}
export const CREATE_CONNECTED_USER = gql`
  mutation ($connectedUser: UpdateConnectedUserInput!) {
    updateConnectedUser(connectedUser: $connectedUser) {
      id
    }
  }
`;

const GET_CURRENT_CONNECTED_USER = gql`
  query GetCurrentUser($id: ID!) {
    currentUser {
      companies {
        id
        name
      }
      connectedUser(id: $id) {
        profile {
          name
          position
        }
        companies {
          id
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
  },
});

export const EditConnectedUser = () => {
  // NOTE: converted user may lack the position field
  const [state, setState] = useState({
    name: '',
    position: '' as string | null,
    companyIds: [] as string[],
  });

  const {t} = useTranslation();
  const navigate = useNavigate();
  const {mixpanel} = useMixpanelContext();
  const {id} = useParams<params>();

  const classes = useStyles();

  const {
    loading: fetchLoading,
    error: fetchError,
    data: currentUserData,
  } = useQuery(GET_CURRENT_CONNECTED_USER, {variables: {id}});
  const [create, {loading, error}] = useMutation(CREATE_CONNECTED_USER);

  useEffect(() => {
    if (!currentUserData) return;

    const {connectedUser} = currentUserData.currentUser;
    if (!connectedUser) return;

    setState({
      name: connectedUser.profile.name,
      position: connectedUser.profile.position,
      companyIds: connectedUser.companies.map((v: any) => v.id),
    });
  }, [currentUserData]);

  const handleSubmit = async (event: any) => {
    mixpanel.track('click_update_connected_user_button');
    event.preventDefault();
    await create({variables: {connectedUser: {...state, id}}});
    navigate(-1);
  };

  const handleChange = (event: ChangeEvent<{name: string; value: string}>) => {
    const {name, value} = event.target;
    setState({...state, [name]: value});
  };

  const handleSelectPosition = (position: string) => {
    setState({...state, position});
  };

  const handleSelectCampaign = (companyIds: string[]) => {
    setState({...state, companyIds});
  };

  if (fetchLoading) return <LinearProgress style={{flex: 1}} />;
  if (fetchError) return <Alert severity='error'>{fetchError.message}</Alert>;

  const positionOptions = [
    {value: 'Специалист', text: t('Специалист')},
    {value: 'Аккаунт-менеджер', text: t('Аккаунт-менеджер')},
    {value: 'Руководители специалистов', text: t('Руководители специалистов')},
    {value: 'Маркетолог', text: t('Маркетолог')},
    {value: 'Бренд-менеджер', text: t('Бренд-менеджер')},
    {value: 'Директор по маркетингу', text: t('Директор по маркетингу')},
    {value: 'CEO', text: t('CEO')},
  ];

  const companyOptions = currentUserData.currentUser.companies.map(
    (v: any) => ({value: v.id, text: v.name}),
  );

  const renderCompaniesValue = (selected: string[]) => {
    if (selected.length === 0) {
      return t('Назначить проект');
    }
    return t('plurals.companies', {count: selected.length});
  };

  const renderFooter = () => {
    const handleClickCancel = () => {
      navigate(-1);
    };

    const filled =
      state.name.length > 0 &&
      state.position != null &&
      state.position.length > 0;

    return (
      <Box>
        <PrimaryMediumButton
          disabled={loading || !filled}
          onClick={handleSubmit}
        >
          <Trans>Сохранить</Trans>
        </PrimaryMediumButton>
        <SecondaryMediumButton onClick={handleClickCancel}>
          <Trans>Отменить</Trans>
        </SecondaryMediumButton>
      </Box>
    );
  };

  return (
    <ModalPage header={t('Настройки доступа')} footer={renderFooter()}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={4} sm={4} className={classes.wrapper}>
            <Typography>
              <Trans>Имя</Trans>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8}>
            <MediumTextField
              error={Boolean(error)}
              value={state.name}
              placeholder={t('ФИО', 'ФИО')}
              inputProps={{name: 'name'}}
              myProps={{placeholderFocused: ''}}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={classes.wrapper}>
            <Typography>
              <Trans>Должность</Trans>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8}>
            <SecondarySelect
              options={positionOptions}
              value={state.position}
              placeholder={t('Выберите должность', 'Выберите должность')}
              onSelect={handleSelectPosition}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={classes.wrapper}>
            <Typography>
              <Trans>Проект</Trans>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8}>
            <SecondaryMultySelect
              options={companyOptions}
              value={state.companyIds}
              placeholder={t('Назначить проект', 'Назначить проект')}
              renderValue={renderCompaniesValue}
              onSelect={handleSelectCampaign}
            />
          </Grid>
        </Grid>
      </form>
    </ModalPage>
  );
};
