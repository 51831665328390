/** @format */
import {useEffect, useState} from 'react';

import Alert from '@mui/material/Alert';
import {Box, LinearProgress, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import {useFlags} from 'launchdarkly-react-client-sdk';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import adTypeHelpers from 'helpers/adTypeHelpers';
import routerHelpers from 'helpers/routerHelpers';
import {snakeToCamel} from 'helpers/stringHelpers';
import type {Platform, PlatformMediaplan, Provider} from 'types';

import FormRow from './FormRow';
import FormRowNew from './FormRowNew';

const useStyles = makeStyles(theme =>
  createStyles({
    modalDescription: {
      marginBottom: theme.spacing(8),
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    modalSaveButton: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#F1F1F1',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    modalSaveIcon: {
      marginRight: '8px',
    },
  }),
);

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($companyId: ID) {
    currentUser {
      roles
      company(id: $companyId) {
        id
        mediaplan {
          id
          yandex
          google
          vkontakte
          facebook
          vkontakteAds
        }
      }
    }
  }
`;

export const CREATE_MEDIAPLAN = gql`
  mutation CreateMediaplan(
    $companyId: ID!
    $yandex: Int
    $google: Int
    $vkontakte: Int
    $facebook: Int
    $vkontakteAds: Int
  ) {
    createMediaplan(
      companyId: $companyId
      yandex: $yandex
      google: $google
      vkontakte: $vkontakte
      facebook: $facebook
      vkontakteAds: $vkontakteAds
    ) {
      id
    }
  }
`;

export const Mediaplan = () => {
  const navigate = useNavigate();

  const {t} = useTranslation();

  const classes = useStyles();

  const {isNewVersionEnabled} = useFlags();

  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');
  const adType = searchParams.get('adType');

  const [plans, setPlans] = useState({} as PlatformMediaplan);

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {companyId},
  });
  const [createMediaplan] = useMutation(CREATE_MEDIAPLAN);

  useEffect(() => {
    if (!data) return;

    const {company} = data.currentUser;
    if (!company) return;

    const {mediaplan} = company;
    if (!mediaplan) return;

    setPlans({
      yandex: mediaplan.yandex,
      google: mediaplan.google,
      vkontakte: mediaplan.vkontakte,
      facebook: mediaplan.facebook,
      //NOTE: имя поля должно оставаться в camelCase.
      //на это завязана логика автоматического присвоения ей значения
      vkontakteAds: mediaplan.vkontakteAds,
    });
  }, [data]);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const platforms = (): Platform[] => {
    let availablePlatforms = adTypeHelpers.platformsFromAdType(
      adType?.toUpperCase(),
    ) as Platform[];
    if (!data.currentUser.roles.includes('VK_ADS')) {
      availablePlatforms = availablePlatforms.filter(
        v => v !== 'VKONTAKTE_ADS',
      );
    }
    if (routerHelpers.IsRuVersion()) {
      return availablePlatforms;
    }
    return adTypeHelpers.rejectRuPlatforms(availablePlatforms);
  };

  const handleChange = (provider: Provider, value: number) => {
    const newPlans = {...plans};
    newPlans[snakeToCamel(provider.toLowerCase())] = Math.floor(value);
    setPlans(newPlans);
  };

  const handleSave = async () => {
    await createMediaplan({variables: {companyId, ...plans}});
    navigate(-1);
  };

  return isNewVersionEnabled ? (
    <ModalPage
      header={t('Настройки медиаплана', 'Настройки медиаплана')}
      backgroundColor='#202020'
    >
      <Box>
        <Typography className={classes.modalDescription}>
          <Trans>
            Укажите бюджет в месяц, и мы будем наблюдать за ежедневными
            расходами по кабинету. В случае отклонения покажем предупреждение.
          </Trans>
        </Typography>
      </Box>

      {platforms().map((platform, i) => (
        <FormRowNew
          key={i}
          provider={platform as Provider}
          value={plans[snakeToCamel(platform.toLowerCase())]}
          onChange={handleChange}
        />
      ))}
      <Box mt={8}>
        <PrimaryMediumButton
          className={classes.modalSaveButton}
          onClick={handleSave}
        >
          <SaveOutlinedIcon
            className={classes.modalSaveIcon}
            fontSize='small'
            htmlColor='#FFFFFF'
          />
          <Trans>Сохранить</Trans>
        </PrimaryMediumButton>
      </Box>
    </ModalPage>
  ) : (
    <ModalPage header={t('Лимит бюджета в месяц', 'Лимит бюджета в месяц')}>
      <Box mt={4}>
        <Typography variant='body1'>
          <Trans>
            Укажите бюджет в месяц, и мы будем наблюдать за ежедневными
            расходами по кабинету. В случае отклонения покажем предупреждение.
          </Trans>
        </Typography>
      </Box>

      {platforms().map((platform, i) => (
        <FormRow
          key={i}
          provider={platform as Provider}
          value={plans[snakeToCamel(platform.toLowerCase())]}
          onChange={handleChange}
        />
      ))}
      <Box mt={6}>
        <PrimaryMediumButton onClick={handleSave}>
          <Trans>Сохранить</Trans>
        </PrimaryMediumButton>
      </Box>
    </ModalPage>
  );
};
