/** @format */

import {useState} from 'react';

import Alert from '@mui/material/Alert';
import {Box, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';

import {useNavigate, useParams} from 'react-router-dom';

import {useAuthContext} from 'contexts/AuthContext';

import PrimaryLargeButton from 'components/buttons/PrimaryLargeButton';
import routerHelpers from 'helpers/routerHelpers';

const SIGN_IN_GUEST = gql`
  mutation SignInGuest($guestUuid: String!) {
    signInGuest(guestUuid: $guestUuid) {
      authToken {
        token
        type
        user {
          id
          uuid
          phone
          roles
          type
          insertedAt
          profile {
            name
          }
        }
      }
    }
  }
`;

const Welcome = () => {
  const {guestUuid} = useParams();
  const navigate = useNavigate();

  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');

  const authContext = useAuthContext();

  const [signInGuestError, setSignInGuestError] = useState('');
  const [signInGuest, {loading}] = useMutation(SIGN_IN_GUEST);

  if (signInGuestError) {
    return <Alert severity='error'>{signInGuestError}</Alert>;
  }

  const handleClick = async () => {
    try {
      const {data} = await signInGuest({variables: {guestUuid}});
      const {authToken} = data.signInGuest;

      await authContext.signIn(authToken);
      navigate(
        {
          pathname: '/guest/connect',
          search: `?companyId=${companyId}`,
        },
        {replace: true},
      );
    } catch (e) {
      setSignInGuestError((e as Error).message);
    }
  };

  return (
    <Box mt={4}>
      <Typography variant='body1'>
        <Trans>
          AdSensor избавит вас от рутины! Сервист автоматически проверяет ваши
          рекламные аккаунты и ежедневно отправляет отчет с рекомендациями по
          улучшению кампаний.
        </Trans>
      </Typography>

      <Box mt={5}>
        <PrimaryLargeButton
          color='primary'
          variant='contained'
          disabled={loading}
          fullWidth
          onClick={handleClick}
        >
          <Trans>Начать</Trans>
        </PrimaryLargeButton>
      </Box>

      <Box mt={19}>
        <Typography variant='body1'>
          <Trans>
            AdSensor получит доступ только к чтению информации и не сможет
            вносить никакие изменения в настройки ваших кампаний.
          </Trans>
        </Typography>
      </Box>
    </Box>
  );
};

export default Welcome;
