/** @format */

import {Box, Grid, Typography} from '@mui/material';
import {gql, useMutation} from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';

import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';

const HIDE_CONGRATULATION = gql`
  mutation HideCongratulation {
    disableFlag(name: CONGRATULATION)
  }
`;

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: '20px',
  },
}));

const Congratulation = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [hideCongratulation] = useMutation(HIDE_CONGRATULATION);

  const header =
    'Ого! Тебе сегодня везет. Несмотря на то, что ты купил всего ' +
    '1 проверку, мы будем проверять твой аккаунт каждый день ' +
    'и показывать результаты!';

  const handleClose = async () => {
    await hideCongratulation();
  };

  const renderButtons = () => {
    const handleConfigure = async () => {
      await hideCongratulation();
      navigate('/home/settings/telegram', {replace: true});
    };

    const handleSkip = async () => {
      await hideCongratulation();
      navigate('/home/main/status', {replace: true});
    };

    return (
      <Box mt={16}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={5}>
            <PrimaryMediumButton fullWidth onClick={handleConfigure}>
              Настроить бота
            </PrimaryMediumButton>
          </Grid>

          <Grid item xs={12} sm={5}>
            <SecondaryMediumButton fullWidth onClick={handleSkip}>
              {'В другой раз'}
            </SecondaryMediumButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <ModalPage header={header} onClose={handleClose}>
      <Typography className={classes.text}>
        {'Наш бот напишет тебе в Телеграм, как только мы найдем что-то ' +
          'серьезное.'}
      </Typography>

      {renderButtons()}
    </ModalPage>
  );
};

export default Congratulation;
