/** @format */

import {useState} from 'react';

import {Box} from '@mui/material';

import DateGraph from './DateGraph';
import PrimarySmallButton from 'components/buttons/PrimarySmallButton';
import TransparentSmallButton from 'components/buttons/TransparentSmallButton';
import numberHelpers from 'helpers/numberHelpers';
import type {MetricName, PlatformGraphStat} from 'types';

const METRIC_HUMAN_NAMES = {
  impressions: 'Показы',
  clicks: 'Клики',
  cpc: 'Цена клика',
  ctr: 'CTR',
};

type Props = {
  platformGraphStats: PlatformGraphStat[];
};

const MetricGraph = (props: Props) => {
  const [activeMetricName, setActiveMetricName] = useState<MetricName>('cpc');

  const renderMetricButton = (metricName: MetricName, index: number) => {
    const handleClick = () => {
      setActiveMetricName(metricName);
    };

    if (metricName === activeMetricName) {
      return (
        <Box key={index} px={1}>
          <PrimarySmallButton onClick={handleClick}>
            {METRIC_HUMAN_NAMES[metricName]}
          </PrimarySmallButton>
        </Box>
      );
    }

    return (
      <Box key={index} px={1}>
        <TransparentSmallButton onClick={handleClick}>
          {METRIC_HUMAN_NAMES[metricName]}
        </TransparentSmallButton>
      </Box>
    );
  };

  const formatYValue = (metricName: MetricName) => (value: number) => {
    if (metricName === 'cpc') return numberHelpers.formatRUB(value);
    if (metricName === 'ctr') return `${value}%`;

    return value.toString();
  };

  const graphData = props.platformGraphStats.map(v => ({
    dateValue: v.date,
    yValue: v[activeMetricName],
  }));

  // https://stackoverflow.com/a/27563449/3632318
  return (
    <>
      <Box display='flex' mx={-1}>
        {(['impressions', 'clicks', 'cpc', 'ctr'] as MetricName[]).map((v, i) =>
          renderMetricButton(v, i),
        )}
      </Box>

      <Box mt={4}>
        <DateGraph
          data={graphData}
          formatYValue={formatYValue(activeMetricName)}
          showAnomalies
        />
      </Box>
    </>
  );
};

export default MetricGraph;
