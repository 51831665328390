/** @format */

import {useEffect, useState} from 'react';

import Alert from '@mui/material/Alert';
import {
  Box,
  Grid,
  LinearProgress,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {Trans} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';

import BoxPlate from 'components/plates/BoxPlate';
import ConnectRows from 'components/connect/ConnectRows';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import TertiaryMediumButton from 'components/buttons/TertiaryMediumButton';
import adTypeHelpers from 'helpers/adTypeHelpers';
import connectHelpers from 'helpers/connectHelpers';
import constants from 'helpers/constants';
import routerHelpers from 'helpers/routerHelpers';
import {useAuthContext} from 'contexts/AuthContext';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import {CompanyName} from './CompanyName';
import InviteGuest from './InviteGuest';

const PROVIDER_COUNT = 4;

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($companyId: ID!) {
    currentUser {
      isWizardFinished
      roles
      company(id: $companyId) {
        id
        uuid
        name
        tokens {
          lastError
          provider
          accounts {
            id
            name
          }
        }
      }
    }
  }
`;

const SET_WIZARD_FINISHED = gql`
  mutation SetWizardFinished {
    setWizardFinished {
      id
    }
  }
`;

const START = gql`
  mutation Start($companyId: ID!) {
    start(companyId: $companyId)
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: ID!, $name: String!) {
    updateCompany(id: $id, name: $name) {
      id
    }
  }
`;

export const Connect = props => {
  const navigate = useNavigate();

  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');

  const {authToken} = useAuthContext();
  const {mixpanel} = useMixpanelContext();
  const {switchAdType} = useCompanyContext();

  const [showInviteGuest, setShowInviteGuest] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [nameEdited, setNameEdited] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER, {
    variables: {companyId},
  });
  const [setWizardFinished, {loading: setWizardFinishedLoading}] =
    useMutation(SET_WIZARD_FINISHED);
  const [start, {loading: startLoading}] = useMutation(START, {
    variables: {companyId},
  });
  const [updateCompany, {loading: updateCompanyLoading}] =
    useMutation(UPDATE_COMPANY);

  routerHelpers.useCallbackOnReturn(refetch);

  useEffect(() => {
    if (data == null) return;

    setCompanyName(data.currentUser.company.name);
  }, [data]);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {isWizardFinished, company, roles} = data.currentUser;
  const {name, tokens} = company;
  const connectedCount = connectHelpers
    .connectedTokens(tokens)
    .filter(v => v.accounts.length > 0).length;

  const platforms = () => {
    if (routerHelpers.IsRuVersion()) {
      return adTypeHelpers.ALL_PLATFORMS;
    }
    return adTypeHelpers.rejectRuPlatforms(adTypeHelpers.ALL_PLATFORMS);
  };

  const renderTopText = () => {
    return (
      <>
        {!isWizardFinished && (
          <Box mt={4}>
            <Typography variant='body1'>
              <Trans>
                Вы всегда сможете добавить больше рекламных кабинетов или
                отозвать уже выданные доступы в разделе «Настройки» «Управление
                проектами» после завершения регистрации.
              </Trans>
            </Typography>
          </Box>
        )}
        {isWizardFinished && (
          <Box mt={4}>
            <Typography variant='body1'>
              <Trans>
                Вы приступили к добавлению нового проекта «{{name}}». Для
                дальнейшего подключения выберите рекламную площадку для которой
                нужно сделать подключение, кликнув «+».
                <br />
                Далее следуйте инструкции.
              </Trans>
            </Typography>
          </Box>
        )}

        <Box display='flex' mt={3}>
          <Typography variant='body1'>{constants.CHECK_MARK}</Typography>
          <Box ml={2}>
            <Typography variant='body1'>
              <Trans>
                Наш робот умеет только читать. Он не сможет ничего менять в
                ваших кабинетах.
              </Trans>
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  const renderCompany = () => {
    const handleSave = async name => {
      setNameEdited(false);
      await updateCompany({
        variables: {
          id: companyId,
          name: name,
        },
      });

      setCompanyName(name);
      setNameEdited(true);
      mixpanel.track('company_name_inplace_edited');
    };

    return (
      <Grid container>
        <Grid item xs={12} sm={5}>
          <CompanyName
            companyName={companyName}
            authTokenType={authToken.type}
            edited={nameEdited}
            loading={updateCompanyLoading}
            onSave={handleSave}
          />
        </Grid>
      </Grid>
    );
  };

  const renderInviteGuest = () => {
    if (authToken.type === 'GUEST') return null;

    const handleOpen = () => {
      setShowInviteGuest(true);
      mixpanel.track('click_wizard_show_invite_guest');
      if (window.dataLayer) {
        window.dataLayer.push({event: 'app_click_dostup'});
      }
    };

    const handleClose = () => {
      setShowInviteGuest(false);
    };

    return (
      <>
        <Box mt={12}>
          <Typography variant='h1'>
            <Trans>Если у вас нет доступов от кабинетов</Trans>
          </Typography>

          <Box mt={4}>
            <Typography variant='body1'>
              <Trans>
                Отправьте специальную страницу своему маркетологу, он выдаст
                доступы, после этого вы сможете продолжить работу.
              </Trans>
            </Typography>
          </Box>

          <Box mt={6}>
            <TertiaryMediumButton onClick={handleOpen}>
              <Trans>Запросить доступ у специалиста</Trans>
            </TertiaryMediumButton>
          </Box>
        </Box>

        {showInviteGuest && <InviteGuest onClose={handleClose} />}
      </>
    );
  };

  const renderInstructionLink = () => {
    if (authToken.type === 'GUEST') return null;

    const handleInstructionClick = event => {
      mixpanel.track('click_wizard_connect_instruction_button');
      if (window.dataLayer) {
        const link = event.target.href.replace('https://', '');
        window.dataLayer.push({event: `app_link_${link}`});
      }
      return true;
    };

    return (
      <>
        <Box mt={12}>
          <Typography variant='h1'>
            <Trans>Если у вас остались вопросы</Trans>
          </Typography>

          <Box mt={4}>
            <Typography variant='body1'>
              <Link
                href='https://adsensor.ru/instructions'
                target='_blank'
                aria-label='instruction-link'
                onClick={handleInstructionClick}
              >
                <Trans>Инструкция</Trans>
              </Link>
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  const renderButton = () => {
    if (connectedCount === 0) return null;

    const handleClick = async () => {
      mixpanel.track('click_wizard_connect_finish_button');

      const adType = adTypeHelpers.initialAdType(tokens);
      switchAdType(adType);

      if (!isWizardFinished) {
        await setWizardFinished();
      }

      await start();

      if (authToken.type === 'GUEST') {
        navigate('/guest/finish');
        return;
      }

      if (isWizardFinished) {
        navigate('/home/settings/connect');
        return;
      }

      navigate('/home/main/wait');
    };

    return (
      <Box mt={8}>
        <PrimaryMediumButton
          disabled={setWizardFinishedLoading || startLoading}
          onClick={handleClick}
          fullWidth
        >
          <Trans>Продолжить</Trans>
        </PrimaryMediumButton>
      </Box>
    );
  };

  const renderBottomText = () => {
    if (!isMobile) return null;
    if (connectedCount === PROVIDER_COUNT) return null;

    return (
      <Box mt={6}>
        <BoxPlate>
          <Typography variant='body1'>
            <Trans>
              Если в телефоне не сохранены доступы, добавьте кабинеты на ПК и
              продолжайте пользоваться мобилой.
            </Trans>
          </Typography>
        </BoxPlate>
      </Box>
    );
  };

  return (
    <>
      <Typography variant='h1'>
        <Trans>Доступы</Trans>
      </Typography>
      {renderTopText()}
      <Box mt={10}>{renderCompany()}</Box>

      <Box mt={6}>
        <ConnectRows
          canSelectSubaccounts
          companyId={company.id}
          tokens={tokens}
          platforms={platforms()}
          showMetaExtremismCaption={routerHelpers.IsRuVersion()}
          userRole={roles}
        />
      </Box>

      {renderButton()}
      {props.showInviteGuest && renderInviteGuest()}
      {renderInstructionLink()}
      {renderBottomText()}
    </>
  );
};
