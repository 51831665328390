/** @format */

import {useState} from 'react';

import Alert from '@mui/material/Alert';
import {Box, LinearProgress, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import routerHelpers from 'helpers/routerHelpers';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';
import {canCreateCompany} from 'helpers/restriction_helper';

import Form from './Form';

const GET_CURRENT_USER = gql`
  query GetCurrentUser($id: ID) {
    currentUser {
      restrictions {
        aliveCompaniesCount
        totalCompaniesCount
      }
      subscription {
        isTrial
      }
      totalCompaniesCount
      companies {
        id
      }
      company(id: $id) {
        id
        name
      }
    }
  }
`;

const CREATE_COMPANY = gql`
  mutation CreateCompany($name: String!) {
    createCompany(name: $name) {
      id
      uuid
      name
      tokens {
        id
        lastError
        provider
      }
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: ID!, $name: String!) {
    updateCompany(id: $id, name: $name) {
      id
      uuid
      name
      tokens {
        id
        lastError
        provider
      }
    }
  }
`;

const Company = () => {
  const navigate = useNavigate();

  const searchParams = routerHelpers.useSearchParams();
  const id = searchParams.get('id');

  const {mixpanel} = useMixpanelContext();
  const {switchCompany} = useCompanyContext();

  const [saveCompanyError, setSaveCompanyError] = useState('');

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {id},
  });
  const [createCompany, {loading: createCompanyLoading}] =
    useMutation(CREATE_COMPANY);
  const [updateCompany, {loading: updateCompanyLoading}] =
    useMutation(UPDATE_COMPANY);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {company, subscription, restrictions, totalCompaniesCount, companies} =
    data.currentUser;

  const handleNext = async (name: string) => {
    try {
      if (company) {
        mixpanel.track('click_wizard_update_company_button');

        const {data: updateCompanyData} = await updateCompany({
          variables: {id: company.id, name: name},
        });

        switchCompany(updateCompanyData.updateCompany);

        navigate({
          pathname: 'connect',
          search: `?companyId=${company.id}`,
        });
        return;
      }

      mixpanel.track('click_wizard_create_company_button');

      const {data: createCompanyData} = await createCompany({
        variables: {name: name},
      });

      switchCompany(createCompanyData.createCompany);

      const companyId = createCompanyData.createCompany.id;
      navigate(`/home/settings/wizard/company/connect?companyId=${companyId}`);
    } catch (e) {
      const message = e instanceof Error ? e.message : 'Unknown error.';
      setSaveCompanyError(message);
    }
  };

  const canCreate = canCreateCompany(
    totalCompaniesCount,
    companies.length,
    restrictions.totalCompaniesCount,
    restrictions.aliveCompaniesCount,
  );

  return (
    <>
      <Typography variant='h1'>
        <Trans>Создание проекта</Trans>
      </Typography>

      <Box mt={6}>
        <Form
          initialName={company?.name}
          canCreate={canCreate}
          loading={createCompanyLoading || updateCompanyLoading}
          isTrial={subscription.isTrial}
          isNew={!id}
          saveError={saveCompanyError}
          onSave={handleNext}
        />
      </Box>
    </>
  );
};

export default Company;
