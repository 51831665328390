/** @format */

import {useMemo} from 'react';

import clsx from 'clsx';
import {Box, Link, ListItem, ListItemText, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PowerOffOutlinedIcon from '@mui/icons-material/PowerOffOutlined';

import BudgetScoreStatus from 'components/BudgetScoreStatus';

import {AdType} from 'types';
import {
  GOOD_BREAKPOINT,
  NORMAL_BREAKPOINT,
  UNOPTIMAL_BREAKPOINT,
} from 'constants/budgetScoreBreakpoints';

export const useStyles = makeStyles(theme =>
  createStyles({
    row: {
      backgroundColor: theme.palette.action.disabledBackground,
      cursor: 'pointer',
      borderRadius: '8px',
      borderRight: '2px solid #AE5658',
      borderLeft: '2px solid #AE5658',
      marginTop: theme.spacing(2),
      padding: theme.spacing(3.5),
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    statusContainer: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: theme.spacing(5),
        '& > div': {
          marginTop: theme.spacing(1),
        },
      },
    },
    statusContainerNoAccess: {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(3),
        width: '100%',
      },
    },
    noAccessStatusContainer: {
      backgroundColor: '#AE5658',
      flexWrap: 'wrap',
    },
    statusItem: {
      width: 56,
      height: 56,
      borderRadius: 4,
    },
    noAccessStatus: {
      backgroundColor: 'transparent',
      border: '1px solid #F1F1F1',
      marginRight: '16px',
    },
    leftTextContainer: {
      minWidth: 'unset',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rightTextContainer: {
      flexGrow: 0,
      minWidth: theme.spacing(19),
    },
    foundIconContainer: {
      flexGrow: 0,
      minWidth: 'unset',
    },
    yellowBadge: {
      borderColor: '#EFAE54',
    },
    redBadge: {
      borderColor: theme.palette.error.main,
    },
    grayBadge: {
      borderColor: '#2B2B2B',
    },
    yellowNew: {
      color: '#EFAE54',
    },
    redNew: {
      color: theme.palette.error.main,
    },
    grayNew: {
      color: 'gray',
    },
    errorsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minWidth: '139px',
      height: '60px',
      padding: theme.spacing(0, 5),
      marginRight: theme.spacing(2),
      borderRadius: '8px',
      background: '#2B2B2B',
      color: '#F1F1F1',
      fontSize: '18px',
      fontWeight: 400,
      textAlign: 'center',
      lineHeight: '20px',
      '& > span': {
        color: '#8B8B8B',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0.169px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginRight: 0,
      },
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '26px',
      letterSpacing: '0.169px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
    statusLogo: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    titleNoAccess: {
      fontSize: '14px',
      lineHeight: 'normal',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
    subTitleNoAccess: {
      fontWeight: 400,
    },
    text: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '17px',
    },
    successText: {
      color: theme.palette.success.main,
    },
    foundText: {
      color: theme.palette.text.primary,
    },
    newText: {
      color: theme.palette.error.main,
    },
    noTokensStatusContainer: {
      background: '#AA8258',
      boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
      borderColor: 'transparent !important',
      flexWrap: 'wrap',
    },
    howToLink: {
      display: 'flex',
      height: '50px',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 32px',
      borderRadius: '8px',
      color: '#fff',
      border: `1px solid ${theme.palette.text.primary}`,
      boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
      [theme.breakpoints.down('xl')]: {
        width: '100%',
      },
      fontSize: '14px',
      fontWeight: 400,
      '& svg': {
        marginRight: '8px',
      },
      '&:hover': {
        textDecoration: 'none',
      },
    },
    howToLinkIcon: {
      fontSize: '18px',
    },
    backdrop: {
      backgroundColor: theme.backdrop.main,
      position: 'absolute',
      zIndex: 1,
    },
    inProgressText: {
      marginRight: '16px',
      color: '#8B8B8B',
      fontSize: '14px',
      fontWeight: 400,
    },
    inProgressStatus: {
      backgroundColor: '#2B2B2B',
    },
    noAccessText: {
      marginRight: '16px',
      color: '#F1F1F1',
      fontSize: '14px',
      fontWeight: 400,
    },
    badStatus: {
      borderColor: '#AE5658',
    },
    normalStatus: {
      borderColor: '#5C5C3F',
    },
    goodStatus: {
      borderColor: '#657B3C',
    },
    unOptimalStatus: {
      borderColor: '#AA8258',
    },
    neutralStatus: {
      borderColor: '#9D9D9D',
    },
  }),
);

export interface Props {
  adType?: AdType;
  isLaunched: boolean;
  budgetScore: number;
  lastCheckCollectDate?: string | null;
  isNoAccessToAccount?: boolean;
  areNoTokens?: boolean;
  recomendations?: number;
  newErrors?: number;
}

const Row = (props: Props) => {
  const classes = useStyles();
  const {t} = useTranslation();

  const checkClassName = useMemo(() => {
    if (props.isLaunched) {
      return classes.neutralStatus;
    }

    if (props.budgetScore === GOOD_BREAKPOINT) {
      return classes.goodStatus;
    }

    if (props.budgetScore >= NORMAL_BREAKPOINT) {
      return classes.normalStatus;
    }

    if (props.budgetScore >= UNOPTIMAL_BREAKPOINT) {
      return classes.unOptimalStatus;
    }

    return classes.badStatus;
  }, [props.isLaunched, props.budgetScore, classes]);

  const renderAdStatus = (
    text: string,
    statusClassName: string,
    icon: React.ReactChild,
  ) => (
    <>
      <span className={classes.noAccessText}>{text}</span>

      <Box alignItems='center' display='flex' justifyContent='center'>
        <Box
          alignItems='center'
          display='flex'
          justifyContent='center'
          className={clsx(classes.statusItem, statusClassName)}
        >
          {icon}
        </Box>
      </Box>
    </>
  );

  return (
    <Box position='relative'>
      <ListItem
        className={clsx(
          classes.row,
          !props.isNoAccessToAccount && checkClassName,
          !props.isNoAccessToAccount &&
            props.lastCheckCollectDate === null &&
            classes.grayBadge,
          props.isNoAccessToAccount && classes.noAccessStatusContainer,
          props.areNoTokens && classes.noTokensStatusContainer,
        )}
      >
        <ListItemText
          className={classes.leftTextContainer}
          disableTypography
          primary={
            <>
              {(props.isNoAccessToAccount || props.areNoTokens) && (
                <Box alignItems='center' display='flex' justifyContent='center'>
                  <Box
                    alignItems='center'
                    display='flex'
                    justifyContent='center'
                    className={clsx(classes.statusItem, classes.noAccessStatus)}
                  >
                    {props.areNoTokens ? (
                      <PowerOffOutlinedIcon />
                    ) : (
                      <ReportOutlinedIcon />
                    )}
                  </Box>
                </Box>
              )}

              <Typography
                className={clsx(
                  classes.title,
                  props.isNoAccessToAccount && classes.titleNoAccess,
                  props.areNoTokens && classes.titleNoAccess,
                )}
              >
                <Trans>
                  {!props.areNoTokens
                    ? props.adType === 'CONTEXT'
                      ? 'Контекст'
                      : 'Таргет'
                    : ''}
                </Trans>

                <span
                  className={clsx(
                    props.isNoAccessToAccount && classes.subTitleNoAccess,
                  )}
                >
                  {props.isNoAccessToAccount &&
                    `: ${t(
                      'Проблема с доступом к кабинету рекламной системы',
                    )}`}

                  {props.areNoTokens &&
                    `${t('В проекте нет подключенных рекламных кабинетов')}`}
                </span>
              </Typography>
            </>
          }
        />

        <Box
          className={clsx(
            classes.statusContainer,
            props.isNoAccessToAccount && classes.statusContainerNoAccess,
            props.areNoTokens && classes.statusContainerNoAccess,
          )}
          alignItems='center'
          display='flex'
          flexWrap='wrap'
        >
          {props.lastCheckCollectDate === null && !props.areNoTokens ? (
            renderAdStatus(
              t('Данные собираются'),
              classes.inProgressStatus,
              <AccessTimeIcon htmlColor='#8B8B8B' />,
            )
          ) : props.isNoAccessToAccount || props.areNoTokens ? (
            <Link
              className={classes.howToLink}
              href={`${
                props.areNoTokens
                  ? 'https://adsensor.ru/instructions#!/tab/764353588-1'
                  : 'https://adsensor.ru/instructions#!/tab/764353588-2'
              }`}
              target='_blank'
              onClick={e => {
                e.stopPropagation();
              }}
              underline='none'
            >
              <HelpOutlineIcon className={classes.howToLinkIcon} />

              {t(`${props.areNoTokens ? 'Как подключить?' : 'Как исправить?'}`)}
            </Link>
          ) : (
            <>
              {Boolean(props.recomendations) && (
                <div
                  className={classes.errorsContainer}
                  data-testid='errors-container'
                >
                  <span>{t('Рекомендаций')} </span>
                  <Box mt={2}> {props.recomendations}</Box>
                </div>
              )}
              {Boolean(props.newErrors) && (
                <div
                  className={classes.errorsContainer}
                  data-testid='errors-container'
                >
                  <span>{t('Новые')} </span>
                  <Box mt={2}>{props.newErrors}</Box>
                </div>
              )}
              <BudgetScoreStatus
                isLaunched={props.isLaunched}
                budgetScore={props.budgetScore}
                statusClassName={classes.statusLogo}
              />
            </>
          )}
        </Box>
      </ListItem>
    </Box>
  );
};

export default Row;
