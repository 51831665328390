/** @format */

import {Backdrop, Box, ListItem, ListItemText, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import constants from 'helpers/constants';
import {sensorName} from 'helpers/sensor_names';

const useStyles = makeStyles(theme => ({
  row: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: 8,
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    paddingRight: theme.spacing(4),
    position: 'relative',
  },
  avatar: {
    minWidth: 16,
  },
  badge: {
    backgroundColor: '#828282',
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
    bottom: 0,
    left: 0,
    position: 'absolute',
    top: 0,
    width: theme.spacing(2),
  },
  title: {
    color: '#D2D2D2',
    fontSize: 18,
    fontWeight: 'normal',
    lineHeight: '22px',
    paddingRight: theme.spacing(2),
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '16px',
    paddingRight: theme.spacing(2),
  },
  backdrop: {
    backgroundColor: theme.backdrop.main,
    position: 'absolute',
    zIndex: 1,
  },
}));

const ExcludedRow = props => {
  const classes = useStyles();

  const {t} = useTranslation();

  const handleClick = () => {
    props.onClick(props.check);
  };

  return (
    <Box position='relative'>
      <ListItem className={classes.row} onClick={handleClick}>
        <Box className={classes.avatar} />
        <Box className={classes.badge}>{constants.ZWSP}</Box>

        <ListItemText
          disableTypography
          primary={
            <Typography className={classes.title}>
              {t(sensorName(props.check.sensor.name))}
            </Typography>
          }
          secondary={
            <Typography className={classes.subtitle}>
              <Trans>Сенсор отключен</Trans>
            </Typography>
          }
        />
      </ListItem>

      <Backdrop className={classes.backdrop} open={props.blurRow} />
    </Box>
  );
};

export default ExcludedRow;
