/** @format */

import {Grid, Typography} from '@mui/material';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import BoxPlate from 'components/plates/BoxPlate';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    accountPickerTitle: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

type Props = {
  title: string;
  children: React.ReactNode;
};

const PickerBox = (props: Props) => {
  const classes = useStyles();

  return (
    <BoxPlate backgroundColor='#2A2A32'>
      <Grid container>
        <Grid item className={classes.accountPickerTitle} xs={3}>
          <Typography variant='h2'>{props.title}</Typography>
        </Grid>
        <Grid item xs={9}>
          {props.children}
        </Grid>
      </Grid>
    </BoxPlate>
  );
};

export default PickerBox;
