/** @format */

import clsx from 'clsx';
import {Box, Typography} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ButtonPlate from 'components/plates/ButtonPlate';
import GreenRadio from 'components/radios/GreenRadio';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
    },
    accountIdText: {
      color: '#A6A6A6',
    },
    disabled: {
      opacity: 0.3,
    },
  }),
);

type Props = {
  title: string;
  currency?: string;
  subtitle?: string;
  selected: boolean;
  disabled?: boolean;
  onSelect: () => void;
};

const Row = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const rowClick = () => {
    if (!props.disabled) {
      props.onSelect();
    }
  };

  return (
    <Box mt={2} className={clsx(props.disabled && classes.disabled)}>
      <ButtonPlate
        containerStyle={{
          backgroundColor: theme.palette.secondary.main,
          border: '1px solid #E0E0E0',
        }}
        onClick={rowClick}
      >
        <Box
          alignItems='center'
          display='flex'
          flexGrow={1}
          justifyContent='flex-start'
        >
          <GreenRadio
            checked={props.selected}
            iconVariant='check'
            disabled={props.disabled}
            variant='LIGHT'
          />
          <Box ml={4} display='flex' flexGrow={1}>
            <Typography align='left' className={classes.text} display='inline'>
              {props.title}
            </Typography>
            {props.subtitle && props.currency && (
              <Box display='flex' flexGrow={1} justifyContent='space-between'>
                <Typography
                  align='left'
                  className={clsx(classes.text, classes.accountIdText)}
                  display='inline'
                >
                  {props.subtitle}
                </Typography>
                <Typography
                  align='left'
                  className={clsx(classes.text, classes.accountIdText)}
                  display='inline'
                >
                  {props.currency}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </ButtonPlate>
    </Box>
  );
};

export default Row;
