/** @format */
import {useEffect} from 'react';

import * as R from 'ramda';
import Alert from '@mui/material/Alert';
import {Box, Grid, LinearProgress, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {gql, useQuery} from '@apollo/client';
import {useNavigate, useLocation} from 'react-router-dom';
import {useFlags} from 'launchdarkly-react-client-sdk';
import makeStyles from '@mui/styles/makeStyles';
import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded';

import routerHelpers from 'helpers/routerHelpers';

import Breadcrumbs from 'components/Breadcrumbs';
import BackHeader from 'components/BackHeader';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

import Row from './Row';

const useStyles = makeStyles(theme => ({
  headerTitle: {
    color: '#F1F1F1',
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  addSpecialistButton: {
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
      marginTop: '16px',
    },
  },
}));

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      parentUser {
        id
      }
      specialists {
        id
        name
      }
    }
  }
`;

const breadcrumbs = [
  {
    href: '/home/settings',
    title: <Trans>Настройки</Trans>,
  },
  {
    href: '',
    title: <Trans>Уведомления</Trans>,
  },
];

export type Specialist = {
  id: string;
  name: string;
};

export const SpecialistList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {isNewVersionEnabled} = useFlags();

  const classes = useStyles();

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER);

  useEffect(() => {
    if (!data) return;

    if (data.currentUser.parentUser != null) {
      const specialistId = data.currentUser.specialists[0].id;
      navigate(
        {
          pathname: '/home/settings/notifications',
          search: `?specialistId=${specialistId}&hideInfo=1`,
        },
        {replace: true},
      );
    }
  }, [data]);

  routerHelpers.useCallbackOnReturn(refetch);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {specialists}: {specialists: Specialist[]} = data.currentUser;
  const sortedSpecialists = R.sortBy(R.prop('name'), specialists);

  const handleCreate = () => {
    navigate('/home/settings/specialist', {
      state: {background: location},
    });
  };

  return (
    <>
      {isNewVersionEnabled ? (
        <Box mt={12} mb={10}>
          <Breadcrumbs items={breadcrumbs} />
        </Box>
      ) : (
        <Box mb={10}>
          <BackHeader />
        </Box>
      )}

      <Box
        display='flex'
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <Typography className={classes.headerTitle}>
          <Trans>Настройка уведомлений</Trans>
        </Typography>

        <PrimaryMediumButton
          className={classes.addSpecialistButton}
          onClick={handleCreate}
        >
          <Box mr={2} display='flex' alignItems='center'>
            <AddCircleOutlineRounded fontSize='small' />
          </Box>
          <Trans>Добавить специалиста</Trans>
        </PrimaryMediumButton>
      </Box>

      <Box mt={6}>
        <Grid container spacing={3}>
          {sortedSpecialists.map((v, i) => (
            <Grid key={i} item xs={12}>
              <Row specialist={v} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
