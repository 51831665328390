/** @format */

import {Box, Container, CssBaseline, Hidden, Link} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useFlags} from 'launchdarkly-react-client-sdk';

import TopMenu from 'components/menus/TopMenu';
import TopMenuNew from 'components/menus/TopMenuNew';
import logoUrl from 'images/logo__white.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  content: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  // use padding instead of margin because Firefox ignores marginBottom
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(22),
      paddingTop: theme.spacing(12),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(22),
      paddingTop: theme.spacing(12),
    },
  },
  menu: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(12),
    },
  },
  logo: {
    position: 'absolute',
    marginTop: theme.spacing(9),
    marginLeft: theme.spacing(15),
  },
}));

const SettingsLayout = props => {
  const classes = useStyles();

  const {isNewVersionEnabled} = useFlags();

  const renderDrawer = () => {
    return (
      (<nav className={classes.logo}>
        <Hidden lgDown>
          <Link href='/'>
            <img alt='AdSensor logo' height='30' src={logoUrl} width='136' />
          </Link>
        </Hidden>
      </nav>)
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      {isNewVersionEnabled ? (
        <Box>
          <TopMenuNew />

          <Container maxWidth='md'>{props.children}</Container>
        </Box>
      ) : (
        <>
          {renderDrawer()}

          <Box className={classes.content}>
            <Container className={classes.container} maxWidth='sm'>
              <Box className={classes.menu}>
                <TopMenu />
              </Box>

              {props.children}
            </Container>
          </Box>
        </>
      )}
    </div>
  );
};

export default SettingsLayout;
