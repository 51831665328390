/** @format */

import {ChangeEvent} from 'react';

import * as R from 'ramda';
import {Box, MenuItem, Select, Typography} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Trans, useTranslation} from 'react-i18next';

import imageHelpers from 'helpers/imageHelpers';
import type {Sensor} from './index';
import {sensorName} from 'helpers/sensor_names';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      flexGrow: 1,
      '& .MuiSelect-root': {
        backgroundColor: theme.palette.secondary.main,
        border: '1px solid #DADADA',
        borderRadius: 8,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(3),
      },
      '& .MuiSelect-select': {
        paddingRight: theme.spacing(8),
      },
      '& .MuiSelect-icon': {
        right: 6,
      },
    },
    menuItem: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: '#F0F0F0',
      },
      '&.Mui-selected': {
        backgroundColor: '#E0E0E0',
      },
    },
  }),
);

type Props = {
  sensors: Sensor[];
  value: string;
  onSelect: (id: string) => void;
};

const SensorSelect = (props: Props) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const handleChange = (event: ChangeEvent<{value: unknown}>) => {
    props.onSelect(event.target.value as string);
  };

  const sensors = R.pipe(
    R.sortWith([R.ascend(R.prop('name')), R.descend(R.prop('platform'))]),
    R.groupBy<Sensor, string>(R.prop('name')),
    R.values,
    R.flatten,
  )(props.sensors);

  return (
    <Box display='flex'>
      <Select
        className={classes.select}
        disableUnderline
        displayEmpty
        value={props.value}
        onChange={handleChange}
      >
        <MenuItem className={classes.menuItem} value=''>
          <Trans>Сенсор не выбран</Trans>
        </MenuItem>
        {sensors.map((v, i) => (
          <MenuItem key={i} className={classes.menuItem} value={v.id}>
            <Box
              alignItems='center'
              display='flex'
              flexGrow={1}
              justifyContent='space-between'
            >
              <Typography variant='body1'>{t(sensorName(v.name))}</Typography>
              <img
                alt='Platform'
                height={20}
                src={imageHelpers.platformIconSrc(v.platform, 20)}
                width={20}
              />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SensorSelect;
