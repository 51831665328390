/** @format */

import {ChangeEvent, useEffect, useState} from 'react';

import Alert from '@mui/material/Alert';
import {Box, Grid, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import MediumTextField from 'components/inputs/MediumTextField';
import ModalLoading from 'components/loading/ModalLoading';
import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondarySelect from 'components/selects/SecondarySelect';
import routerHelpers from 'helpers/routerHelpers';
import type {ExceptionItem, Operator, Platform} from 'types';
import {fieldName} from 'helpers/field_names';

const ALL_OPERATORS: Operator[] = ['EQUALS', 'CONTAINS'];

const GET_EXCEPTION_FIELDSET = gql`
  query GetCurrentUser($companyId: ID!, $sensorName: String!) {
    currentUser {
      company(id: $companyId) {
        exceptionFieldsets(sensorName: $sensorName) {
          platform
          fields
        }
      }
    }
  }
`;

const CREATE_EXCEPTION = gql`
  mutation CreateException(
    $companyId: ID!
    $sensorName: String!
    $platform: YalperPlatform!
    $items: [YalperExceptionItemInput!]!
  ) {
    createException(
      companyId: $companyId
      sensorName: $sensorName
      platform: $platform
      items: $items
    )
  }
`;

type ExceptionFieldset = {
  platform: Platform;
  fields: string[];
};

const ExceptionForm = () => {
  const navigate = useNavigate();

  const {t} = useTranslation();

  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');
  const sensorName = searchParams.get('sensorName');
  const platform = searchParams.get('platform');

  const [items, setItems] = useState<ExceptionItem[]>([]);

  const {loading, error, data} = useQuery(GET_EXCEPTION_FIELDSET, {
    variables: {companyId, sensorName},
  });

  const [createException, {loading: createExceptionLoading}] =
    useMutation(CREATE_EXCEPTION);

  useEffect(() => {
    if (!data) return;

    const {exceptionFieldsets}: {exceptionFieldsets: ExceptionFieldset[]} =
      data.currentUser.company;

    const fieldset = exceptionFieldsets.find(v => v.platform === platform);
    const newItems = (fieldset as ExceptionFieldset).fields.map(v => ({
      field: v,
      operator: 'EQUALS' as Operator,
      value: '',
    }));

    setItems(newItems);
  }, [data, platform]);

  if (loading) return <ModalLoading title='Пожалуйста, подождите' />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const renderForm = () => {
    const options = ALL_OPERATORS.map(v => ({
      value: v,
      text: t(`operators.${v}`),
    }));

    const handleSelectOperator = (index: number) => (value: string) => {
      const newItems = [...items];
      newItems[index].operator = value as Operator;
      setItems(newItems);
    };

    const handleChangeValue =
      (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const newItems = [...items];
        newItems[index].value = event.target.value;
        setItems(newItems);
      };

    return (
      <Box mt={5}>
        <Grid container alignItems='center' spacing={2}>
          {items.map((v, i) => (
            <>
              <Grid item xs={6} sm={4}>
                <Typography variant='body1'>{t(fieldName(v.field))}</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <SecondarySelect
                  options={options}
                  value={v.operator}
                  onSelect={handleSelectOperator(i)}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <MediumTextField
                  value={v.value}
                  onChange={handleChangeValue(i)}
                />
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
    );
  };

  const renderButton = () => {
    const handleClick = async () => {
      await createException({
        variables: {
          companyId,
          sensorName,
          platform,
          items: items.filter(v => v.value),
        },
      });

      navigate(-1);
    };

    return (
      <Box mt={10}>
        <PrimaryMediumButton
          disabled={createExceptionLoading}
          onClick={handleClick}
        >
          <Trans>Сохранить</Trans>
        </PrimaryMediumButton>
      </Box>
    );
  };

  return (
    <ModalPage header={t('Добавление исключения', 'Добавление исключения')}>
      {renderForm()}
      {renderButton()}
    </ModalPage>
  );
};

export default ExceptionForm;
