/** @format */

import {Container, CssBaseline} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(12),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(12),
      maxWidth: 700,
    },
  },
}));

type Props = {
  children: React.ReactChild;
};

const BaseLayout = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container className={classes.container} disableGutters maxWidth='xs'>
        {props.children}
      </Container>
    </div>
  );
};

export default BaseLayout;
