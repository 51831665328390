/** @format */

import {useState} from 'react';

import Alert from '@mui/material/Alert';
import {Typography} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import ModalLoading from 'components/loading/ModalLoading';
import ModalPage from 'components/ModalPage';
import routerHelpers from 'helpers/routerHelpers';

import {useMixpanelContext} from 'contexts/MixpanelContext';

import type {account} from './Form';
import Form from './Form';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($companyId: ID!, $provider: OauthProvider!) {
    currentUser {
      restrictions {
        subaccountsCount
      }
      subscription {
        isTrial
      }
      company(id: $companyId) {
        name
        token(provider: $provider) {
          id
          accounts {
            id
            name
          }
        }
        accounts(provider: $provider) {
          id
          name
          currency
        }
      }
    }
  }
`;

const UPDATE_PROVIDER_TOKEN = gql`
  mutation UpdateProviderToken(
    $id: ID!
    $provider: OauthProvider!
    $accounts: [YalperAccountInput!]!
  ) {
    updateProviderToken(id: $id, provider: $provider, accounts: $accounts) {
      id
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.text.secondary,
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '23px',
    },
  }),
);

export function Subaccounts() {
  const navigate = useNavigate();

  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');
  const provider = searchParams.get('provider');

  const {mixpanel} = useMixpanelContext();

  const {t} = useTranslation();
  const classes = useStyles();

  const [saveProviderTokenError, setSaveProviderTokenError] = useState('');

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {companyId, provider},
  });
  const [updateProviderToken, {loading: updateProviderTokenLoading}] =
    useMutation(UPDATE_PROVIDER_TOKEN);

  const renderHeader = () => {
    return (
      <Typography className={classes.title}>
        <Trans>Выберите один или несколько клиентских аккаунтов</Trans>
      </Typography>
    );
  };

  if (loading) {
    return (
      <ModalLoading
        title={t('Пожалуйста, подождите', 'Пожалуйста, подождите')}
      />
    );
  }

  if (error) {
    const errorMessage = () => {
      if (error.message.includes('invalid_yandex_token')) {
        mixpanel.track('invalid_yandex_token', {});
        const handleClick = () => {
          mixpanel.track('open_instruction_reconnect_token', {});
        };

        return (
          <Alert severity='error'>
            Проблема с доступом к кабинету. Пожалуйста переподключите доступ.
            <br />
            <a
              href='https://adsensor.ru/instructions#rec527787650'
              target='blank'
              onClick={handleClick}
            >
              Инструкция
            </a>
          </Alert>
        );
      }
      return <Alert severity='error'>{error.message}</Alert>;
    };

    return (
      <ModalPage backgroundColor='#F6F6F6' header={renderHeader()}>
        {errorMessage()}
      </ModalPage>
    );
  }

  if (saveProviderTokenError) {
    return <Alert severity='error'>{saveProviderTokenError}</Alert>;
  }

  const {company, restrictions, subscription} = data.currentUser;
  const {token} = company;

  const handleSave = async (accounts: account[]) => {
    mixpanel.track('click_wizard_accounts_continue_button', {provider});

    try {
      await updateProviderToken({
        variables: {id: token.id, provider, accounts},
      });

      navigate(-1);
    } catch (e) {
      setSaveProviderTokenError((e as Error).message);
    }
  };

  return (
    <ModalPage backgroundColor='#F6F6F6' header={renderHeader()}>
      <Form
        maxSubaccountsCount={restrictions.subaccountsCount}
        companyAccounts={company.accounts}
        tokenAccounts={token.accounts}
        disableButtons={updateProviderTokenLoading}
        isTrial={subscription.isTrial}
        onSave={handleSave}
      />
    </ModalPage>
  );
}
