/** @format */
import CloseIcon from '@mui/icons-material/Close';
import {Box, Hidden, IconButton} from '@mui/material';
import {useTranslation} from 'react-i18next';

import Hr from 'components/SidebarMenu/Hr';
import MenuItemLink from 'components/SidebarMenu/MenuItemLink';
import callGreen from 'images/call_green.png';
import callGrey from 'images/call_grey.png';
import externalLink from 'images/external_link.png';
import logoDesktopImg from 'images/logo_desktop.png';
import logoMobileImg from 'images/logo_mobile.png';
import routerHelpers from 'helpers/routerHelpers';

type Props = {
  onToggleDrawer?: () => void;
};

const Sidebar = (props: Props) => {
  const {t} = useTranslation();

  const renderHeader = () => {
    return (<>
      <Hidden mdUp>
        <Box
          alignItems='center'
          display='flex'
          justifyContent='space-between'
        >
          <img alt='AdSensor logo' height='26' src={logoMobileImg} width='115'/>
          <IconButton color='inherit' edge='start' onClick={props.onToggleDrawer} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </Hidden>
      <Hidden mdDown>
        <Box display='flex' justifyContent='center' mt={5}>
          <img alt='AdSensor logo' height='30' src={logoDesktopImg} width='136'/>
        </Box>
      </Hidden>
    </>);
  };

  return (
    <Box display='flex' flexDirection='column' pl={3} pr={3} pb={5}>
      {renderHeader()}
      <Box mt={18} display='flex' flexDirection='column'>
        <MenuItemLink
          text={t("Нужна помощь")}
          to={{pathname: '/guest/help'}}
          activeImg={callGreen}
          inactiveImg={callGrey}
          onClick={props.onToggleDrawer}
        />
        {routerHelpers.IsRuVersion() && (
          <>
            <Box mx={3}>
              <Hr />
            </Box>
            <MenuItemLink
              text={t("Справочные\nматериалы")}
              to={{pathname: 'https://adsensor.ru/how'}}
              openBlank
              activeImg={externalLink}
              inactiveImg={externalLink}
            />
            <MenuItemLink
              text={t("Для специалистов")}
              to={{pathname: 'https://adsensor.ru/sensors'}}
              openBlank
              activeImg={externalLink}
              inactiveImg={externalLink}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;
