/** @format */
import Alert from '@mui/material/Alert';
import {Box, LinearProgress, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import {useCompanyContext} from 'contexts/CompanyContext';
import adTypeHelpers from 'helpers/adTypeHelpers';

const GET_COMPANY = gql(`
  query GetSharedCompany($uuid: String!) {
    sharedCompany(uuid: $uuid) {
      name
    }
    currentUser {
      type
      isWizardFinished
      profile {
        id
      }
    }
  }
`);

const CREATE_PROFILE = gql`
  mutation CreateProfile(
    $type: AccountsUserType!
    $name: String!
    $quiz: [AccountsQuizItemInput!]!
    $budget: Int!
  ) {
    updateUserType(type: $type) {
      id
    }
    createProfile(name: $name, quiz: $quiz) {
      id
    }
    updateBudget(budget: $budget) {
      id
    }
  }
`;

const JOIN_COMPANY = gql(`
  mutation JoinCompany($uuid: String!) {
    setWizardFinished {
      id
    }
    createFreeSubscription
    joinCompany(uuid: $uuid) {
      id
      uuid
      name
      tokens {
        id
        provider
      }
    }
  }
`);

interface Params {
  uuid: string;
}

const Company = () => {
  const {uuid} = useParams<Params>();
  const navigate = useNavigate();

  const {switchAdType, switchCompany} = useCompanyContext();

  const {
    loading: loadingCompany,
    error,
    data,
  } = useQuery(GET_COMPANY, {
    variables: {uuid},
  });
  const [createProfile, {loading: createProfileLoading}] =
    useMutation(CREATE_PROFILE);
  const [joinCompany, {loading: loadingJoinCompany}] =
    useMutation(JOIN_COMPANY);

  if (loadingCompany) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {profile} = data.currentUser;

  const handleClick = async () => {
    if (!profile) {
      await createProfile({
        variables: {
          type: 'BUSINESS',
          name: 'Приглашенный пользователь',
          quiz: [],
          budget: 1_000,
        },
      });
    }
    const {data} = await joinCompany({variables: {uuid}});
    const adType = adTypeHelpers.initialAdType(data.joinCompany.tokens);

    switchCompany(data.joinCompany);
    switchAdType(adType);
    navigate('/home/main', {replace: true});
  };

  const companyName = data.sharedCompany.name;

  return (
    <Box mt={4}>
      <Typography>
        <Trans>Вам предоставлен доступ к проекту "{{companyName}}"</Trans>
      </Typography>
      <Box mt={5}>
        <PrimaryMediumButton
          disabled={
            loadingCompany || loadingJoinCompany || createProfileLoading
          }
          fullWidth
          onClick={handleClick}
        >
          <Trans>Присоединиться</Trans>
        </PrimaryMediumButton>
      </Box>
    </Box>
  );
};

export default Company;
