/** @format */

import {ChangeEvent} from 'react';
import {styled} from '@mui/material/styles';
import clsx from 'clsx';

import {Box, MenuItem, Select} from '@mui/material';

import Hint from 'components/Hint';

const PREFIX = 'SecondarySelectWithLabel';

const classes = {
  select: `${PREFIX}-select`,
  placeholder: `${PREFIX}-placeholder`,
  menuItem: `${PREFIX}-menuItem`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({theme}) => ({
  [`& .${classes.select}`]: {
    flexGrow: 1,
    '& .MuiSelect-root': {
      backgroundColor: theme.palette.secondary.main,
      border: '1px solid #DADADA',
      borderRadius: 8,
      color: theme.palette.text.secondary,
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(3),
    },
    '& .MuiSelect-select': {
      paddingRight: theme.spacing(8),
    },
    '& .MuiSelect-icon': {
      right: 6,
    },
  },

  [`& .${classes.placeholder}`]: {
    '& .MuiSelect-select': {
      color: '#9C9C9C',
    },
  },

  [`& .${classes.menuItem}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: '#F0F0F0',
    },
    '&.Mui-selected': {
      backgroundColor: '#E0E0E0',
    },
  },
}));

type Option = {
  value: string;
  text: string;
};

type Props = {
  disabled?: boolean;
  hint?: string;
  placeholder: string;
  options: Option[];
  value: string;
  onSelect: (value: string) => void;
};

const SecondarySelectWithLabel = (props: Props) => {
  const {disabled = false} = props;

  const handleChange = (event: ChangeEvent<{value: unknown}>) => {
    props.onSelect(event.target.value as string);
  };

  const cls = [classes.select];
  if (props.value === '') {
    cls.push(classes.placeholder);
  }

  return (
    <Root>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Select
          className={clsx(cls)}
          disableUnderline
          disabled={disabled}
          displayEmpty
          value={props.value}
          // @ts-ignore
          onChange={handleChange}
        >
          <MenuItem value='' disabled>
            {props.placeholder}
          </MenuItem>
          {props.options.map((v, i) => (
            <MenuItem key={i} className={classes.menuItem} value={v.value}>
              {v.text}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {props.hint && <Hint text={props.hint} />}
    </Root>
  );
};

export default SecondarySelectWithLabel;
