/** @format */

import {Navigate, Routes, Route, useLocation} from 'react-router-dom';
import {useFlags} from 'launchdarkly-react-client-sdk';

import {ROUTES} from 'constants/ROUTES';

import MainLayout from 'layouts/MainLayout';
import MainLayoutNew from 'layouts/MainLayoutNew';

import PrivateRoute from 'components/PrivateRoute';

import BadWords from './BadWords';
import BudgetScoreDescription from './BudgetScoreDescription';

import BusinessMetrics from './BusinessMetrics';
import Category from './Category';
import CategoryNew from './CategoryNew';
import Congratulation from './Congratulation';
import CorrectWords from './CorrectWords';
import Details from './Details';
import ErrorDescription from './ErrorDescription';
import GeoGroups from './GeoGroups';
import Graphs from './Graphs';

import Regions from './Regions';
import Report from './Report';
import ScoreDescription from './ScoreDescription';
import Status from './Status';
import StopWords from './StopWords';
import Wait from './Wait';
import WaitForPayment from './WaitForPayment';
import {ErrorSettings} from './ErrorSettings';
import {Share} from './Share';

const Main = () => {
  const location = useLocation();

  const {isNewVersionEnabled} = useFlags();

  // https://reactrouter.com/web/example/modal-gallery
  const background = location.state && location.state.background;

  if (isNewVersionEnabled) {
    return (
      <MainLayoutNew>
        <Routes location={background || location}>
          <Route
            path={ROUTES.MAIN_WAIT}
            element={
              <PrivateRoute>
                <Wait />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.MAIN_STATUS}/*`}
            element={
              <PrivateRoute>
                <Status />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_CATEGORY}
            element={
              <PrivateRoute>
                <Category />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_DETAILS}
            element={
              <PrivateRoute>
                <Details />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_CONGRATULATION}
            element={
              <PrivateRoute>
                <Congratulation />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_BUSINESS_METRICS}
            element={
              <PrivateRoute>
                <BusinessMetrics />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_GRAPHS}
            element={
              <PrivateRoute>
                <Graphs />
              </PrivateRoute>
            }
          />
          <Route
            index
            element={
              <PrivateRoute>
                <Navigate to={`${ROUTES.MAIN_STATUS}`} />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_WAIT_FOR_PAYMENT}
            element={
              <PrivateRoute>
                <WaitForPayment />{' '}
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_ERROR_SETTINGS}
            element={
              <PrivateRoute>
                <ErrorSettings />{' '}
              </PrivateRoute>
            }
          />
        </Routes>

        {background && (
          <Routes>
            <Route
              path={ROUTES.MAIN_ERROR_DESCRIPTION}
              element={
                <PrivateRoute>
                  <ErrorDescription />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.MAIN_REGIONS}
              element={
                <PrivateRoute>
                  <Regions />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.MAIN_BAD_WORDS}
              element={
                <PrivateRoute>
                  <BadWords />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.MAIN_CORRECT_WORDS}
              element={
                <PrivateRoute>
                  <CorrectWords />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.MAIN_GEO_GROUPS}
              element={
                <PrivateRoute>
                  <GeoGroups />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.MAIN_STOP_WORDS}
              element={
                <PrivateRoute>
                  <StopWords />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.MAIN_SHARE}
              element={
                <PrivateRoute>
                  <Share />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.MAIN_SCORE_DESCRIPTION}
              element={
                <PrivateRoute>
                  <ScoreDescription />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.MAIN_BUDGET_SCORE_DESCRIPTION}
              element={
                <PrivateRoute>
                  <BudgetScoreDescription />
                </PrivateRoute>
              }
            />
            <Route
              path={`${ROUTES.MAIN_REPORT}/*`}
              element={
                <PrivateRoute>
                  <Report />
                </PrivateRoute>
              }
            />
          </Routes>
        )}
      </MainLayoutNew>
    );
  }

  return (
    <MainLayout>
      <Routes location={background || location}>
        <Route
          path={ROUTES.MAIN_WAIT}
          element={
            <PrivateRoute>
              <Wait />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.MAIN_STATUS}/*`}
          element={
            <PrivateRoute>
              <Status />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MAIN_CATEGORY}
          element={
            <PrivateRoute>
              <Category />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MAIN_DETAILS}
          element={
            <PrivateRoute>
              <Details />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MAIN_CONGRATULATION}
          element={
            <PrivateRoute>
              <Congratulation />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MAIN_BUSINESS_METRICS}
          element={
            <PrivateRoute>
              <BusinessMetrics />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MAIN_GRAPHS}
          element={
            <PrivateRoute>
              <Graphs />
            </PrivateRoute>
          }
        />
        <Route
          index
          element={
            <PrivateRoute>
              <Navigate to={`${ROUTES.MAIN_STATUS}`} />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MAIN_WAIT_FOR_PAYMENT}
          element={
            <PrivateRoute>
              <WaitForPayment />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.MAIN_ERROR_SETTINGS}
          element={
            <PrivateRoute>
              <ErrorSettings />
            </PrivateRoute>
          }
        />
      </Routes>

      {background && (
        <Routes>
          <Route
            path={ROUTES.MAIN_ERROR_DESCRIPTION}
            element={
              <PrivateRoute>
                <ErrorDescription />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_REGIONS}
            element={
              <PrivateRoute>
                <Regions />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_BAD_WORDS}
            element={
              <PrivateRoute>
                <BadWords />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_CORRECT_WORDS}
            element={
              <PrivateRoute>
                <CorrectWords />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_GEO_GROUPS}
            element={
              <PrivateRoute>
                <GeoGroups />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_STOP_WORDS}
            element={
              <PrivateRoute>
                <StopWords />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_SHARE}
            element={
              <PrivateRoute>
                <Share />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_SCORE_DESCRIPTION}
            element={
              <PrivateRoute>
                <ScoreDescription />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN_BUDGET_SCORE_DESCRIPTION}
            element={
              <PrivateRoute>
                <BudgetScoreDescription />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.MAIN_REPORT}/*`}
            element={
              <PrivateRoute>
                <Report />
              </PrivateRoute>
            }
          />
        </Routes>
      )}
    </MainLayout>
  );
};

export default Main;
