/** @format */

import {Box, Grid, Typography, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import {useTheme} from '@mui/material/styles';

import BoxPlate from 'components/plates/BoxPlate';
import TransparentSmallButton from 'components/buttons/TransparentSmallButton';
import type {Exception} from 'types';
import {fieldName} from 'helpers/field_names';

const DELETE_EXCEPTION = gql`
  mutation DeleteException($id: ID!) {
    deleteException(id: $id)
  }
`;

type Props = {
  exception: Exception;
  onDelete: () => void;
};

const Row = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {t} = useTranslation();

  const [deleteException, {loading: deleteExceptionLoading}] =
    useMutation(DELETE_EXCEPTION);

  const handleDelete = async () => {
    await deleteException({variables: {id: props.exception.id}});
    props.onDelete();
  };

  return (
    <BoxPlate>
      <Grid container alignItems='center' spacing={2}>
        {props.exception.items.map(v => (
          <>
            <Grid item xs={6} sm={4}>
              <Typography variant='body1'>
                {t(fieldName(v.field))}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant='body1'>
                {t(`operators.${v.operator}`)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography align={isMobile ? 'left' : 'right'} variant='body1'>
                {v.value}
              </Typography>
            </Grid>
          </>
        ))}
      </Grid>

      <Box display='flex' justifyContent='flex-end' mt={4}>
        <TransparentSmallButton
          disabled={deleteExceptionLoading}
          onClick={handleDelete}
        >
          Удалить
        </TransparentSmallButton>
      </Box>
    </BoxPlate>
  );
};

export default Row;
